import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DiseasesActions from '../actions/diseases.actions';
import { DirectoryService } from '@app/core/services/directory.service';

@Injectable()
export class DiseasesEffects {
  loadDiseases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiseasesActions.loadDiseases),
      switchMap(({ type, ...params }) => {
        return this.directoryService.loadDiseases(params).pipe(
          map((response) => {
            return DiseasesActions.loadDiseasesSuccess({
              diseases: response,
            });
          }),
          catchError((error) => of(DiseasesActions.loadDiseasesFailure({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private directoryService: DirectoryService,
  ) {}
}

import { createAction, props } from '@ngrx/store';

export const loadRecipeGroup = createAction(
  '[Directory / API] Load Recipe Group'
);
export const loadRecipeGroupSuccess = createAction(
  '[Directory / API] Load Recipe Group Success',
  props<{ recipeGroup: any[] }>()
);
export const loadRecipeGroupFailure = createAction(
  '[Directory / API] Load Recipe Group Failure',
  props<{ error: any }>()
);

export const clearRecipeGroup = createAction(
  '[Directory] Clear Recipe Group',
);

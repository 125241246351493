import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as UnitsActions from '../actions/units.actions';

export const unitsFeatureKey = 'units';

export interface State extends EntityState<any> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (unit: any) => unit.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(UnitsActions.loadUnits, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(UnitsActions.loadUnitsSuccess, (state, { units }) => ({
    ...adapter.setAll(units, state),
    isLoading: false,
  })),

  on(UnitsActions.loadUnitsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectUnitsEntities = selectEntities;
export const selectAllUnits = selectAll;

export const selectIsLoading = (state: State) => state.isLoading;

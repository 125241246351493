import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as CookingTypeActions from '../actions/cooking-type.actions';
import * as CookingTypeSelectors from '../selectors/cooking-type.selectors';
import { DirectoryService } from '@app/core/services/directory.service';
import * as fromShared from '@app/shared/store';
import { select, Store } from '@ngrx/store';

@Injectable()
export class CookingTypeEffects {
  loadCookingTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CookingTypeActions.loadCookingTypes),
      withLatestFrom(
        this.store$.pipe(select(CookingTypeSelectors.selectAllCookingTypes)),
      ),
      switchMap(([action, cookingTypeList]) => {
        return (cookingTypeList.length
          ? of(cookingTypeList)
          : this.directoryService.loadCookingTypes()
        ).pipe(
          map((response) => {
            return CookingTypeActions.loadCookingTypesSuccess({
              cookingTypes: response,
            });
          }),
          catchError((error) =>
            of(CookingTypeActions.loadCookingTypesFailure({ error })),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private directoryService: DirectoryService,
    private store$: Store<fromShared.State>,
  ) {}
}

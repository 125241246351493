import { NgModule } from '@angular/core';
import { QuicklinkModule } from 'ngx-quicklink';

@NgModule({
  imports: [
    QuicklinkModule
  ],
  exports: [
    QuicklinkModule
  ],
})
export class PreloadingModule {}

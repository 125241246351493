import {
  createAction,
  props,
} from '@ngrx/store';
import { Dictinary } from '@app/shared/models';

export const loadSportLevelList = createAction(
  '[Directory / API] Load Sport Level List',
);
export const loadSportLevelListSuccess = createAction(
  '[Directory / API] Load Sport Level List Success',
  props<{ sportLevelList: any[] }>(),
);
export const loadSportLevelListFailure = createAction(
  '[Directory / API] Load Sport Level List Failure',
  props<{ error: Dictinary[] }>(),
);

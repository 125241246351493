import { createSelector } from '@ngrx/store';
import * as fromGender from '../reducers/gender.reducer';
import { selectGenderState } from '../reducers';

import { TranslocoService } from '@ngneat/transloco';

export const selectGenderListIsLoading = createSelector(
  selectGenderState,
  fromGender.selectIsLoading,
);

export const selectGenderTypeEntities = createSelector(
  selectGenderState,
  fromGender.selectGenderTypeEntities,
);

export const selectAllGenderTypes = createSelector(
  selectGenderState,
  fromGender.selectAllGenderTypes,
);

export const selectFilterGenderTypes = createSelector(
  selectAllGenderTypes,
  (allGenderTypes) => [...allGenderTypes],
);

export const selectGenderTypeById = (id: number) => createSelector(
    selectGenderTypeEntities,
    (genderTypeEntities) => genderTypeEntities[id],
  );

import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import amplitude from 'amplitude-js';
import { User } from '@app/shared/models';
declare const ym: Function;
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  constructor() {}

  monitorCurrentUser(user: any) {
    this.monitorBySentry(user);
    this.monitorByAmplitude(user);    
    if (environment.production && (<any>window).ym) {
      this.monitorByYandex(user);
    }
  }

  private monitorBySentry(user: any): void {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id,
        email: user.user_profile.email,
        username: user.user_profile.first_name,
      });
    });
  }

  public sendEventToAplitude(event_name: string): void{
    amplitude.getInstance().logEvent(event_name);
  }

  private monitorByYandex(user: any): void {
    ym(environment.yandexMetrika.id, 'userParams', user.user_profile);
  }

  private monitorByAmplitude(user: any): void{
    amplitude.getInstance().setUserId(user.id);
  }

}

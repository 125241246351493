import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as WorkingGroupActions from '../actions/working-group.actions';


export const workingGroupFeatureKey = 'working-group';

export interface State extends EntityState<any> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (workingGroup: any) => workingGroup.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const workingGroupReducer = createReducer(
  initialState,

  on(WorkingGroupActions.loadWorkingGroups, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(WorkingGroupActions.loadWorkingGroupsSuccess, (state, { workingGroups }) => ({
    ...adapter.setAll(workingGroups, state),
    isLoading: false,
  })),

  on(WorkingGroupActions.loadWorkingGroupsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return workingGroupReducer(state, action);
}

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectWorkingGroupEntities = selectEntities;
export const selectAllWorkingGroups = selectAll;

export const selectIsLoading = (state: State) => state.isLoading;

import {DomainsKey} from '@app/shared/enums/domains';
import {Language} from '@app/shared/constants/language';
import {Domain, DomainColor} from '@app/shared/models';

export const DomainsDefaultData: Domain = {
  lang: Language.ru,
  logo: 'np',
  logoMobile: 'logo_brand_mobile',
  favicon: 'np',
  color: DomainColor.DEFAULT,
  name: 'Nutrient Planner',
  link: 'https://nutrientplaner.ru/',
  patientProfileDomain: 'https://askforhealth.ru',
  meta: {
    title: 'Nutrient Planner',
    srcBitrix: 'loader_3_93o20u',
  },
  isInstructionLinkActive: true,
  instructionLink: 'https://nplanner.ru/faq/',
  tutorialYoutubeLink: 'bjZjrLMigAw',
  address: {
    googleLink: 'https://goo.gl/maps/SPCeTLr5CKkpzREr9',
    street: 'Россия, г. Санкт-Петербург, ул. Малая Балканская, д.20, оф.71,',
  },
  termsOfUse: {
    serviceName: 'Nutrient Planner',
    head: 'НастоящиеПравила (далее – Правила) являются неотъемлемой частью Договора-оферты и регулируют отношения между Индивидуальным предпринимателем Зелениным Иваном Владимировичем ОГРНИП 320784700104332,  далее именуемым – Исполнитель, и любым лицом, использующим веб-сервис Nutrient Planner, расположенным в сетиИнтернетпоадресу -https://nutrientplaner.ru/(далее – веб-сервис),и именуемым в дальнейшем –Пользователь.\n' +
      '      Настоящие Правила являются офертой, не требуют двустороннего подписания и действительны в электронном виде с момента ихразмещения на сайте Исполнителя по адресу - https://nplanner.ru/polzovatelskoe-soglashenie\n' +
      '      А факт использования веб-сервиса Nutrient Planner любым способом признается полным и безоговорочным согласием Пользователя с Правилами, в том числе, в части предоставления согласия Исполнителю на обработку персональных данных Пользователя на условиях, указанных в разделе 4 Правил, и на получение рассылок. При этом Пользователь подтверждает факт своего совершеннолетия, дееспособности и правоспособности.',
    date: '14.04.2021',
  },
  offerAgreement: {
    head: 'Индивидуальный предприниматель Зеленин Иван Владимирович ОГРНИП 320784700104332, действующий на основании свидетельства о государственной регистрации в качестве индивидуального предпринимателя, именуемый в дальнейшем Исполнитель, настоящей публичной офертойв соответствии со ст.437 Гражданского кодекса РФ предлагает любому заинтересованному лицу заключить договор на предоставление доступа к веб-сервису Nutrient Planner (далее – веб-сервис, Nutrient Planner).',
    '1.2': 'Акцепт оферты означает заключение договора на условиях, указанных в настоящем договоре в соответствии с п. 1 ст. 433 и п. 3 ст. 438 Гражданского кодекса Российской Федерации. С момента заключения договора такое лицо становится Стороной настоящего договора, в дальнейшем именуемой Заказчиком, и приобретает права и обязанности в соответствии с условиями настоящего договора.',
    informationResourcesLink: 'https://nplanner.ru, сообщества Исполнителя в социальной сети ВКонтакте (https://vk.com/nplanner) и Instagram (https://www.instagram.com/nutrientplanner/, канал Яндекс.Дзен (https://zen.yandex.ru/id/5e4d64d4733a3e3e6ab759d6), а также другие сообщества и ресурсы, созданные после размещения настоящего договора-оферты.',
    references: {
      orgn: 'Индивидуальный предприниматель Зеленин Иван Владимирович\n' + 'ОГРНИП 320784700104332',
      inn: '032607887410',
      pc: '40802810400001483559',
      bank: 'АО "ТИНЬКОФФ БАНК"',
      bik: '044525974',
      ks: '30101810145250000974',
      postalAddress: '192281, Санкт-Петербург, ул. Малая Балканская д.20, лит.А, офис 71',
      email: 'info@nplanner.ru',
      phone: '+7 (812) 603-76-91',
    },
    serviceName: 'Nutrient Planner',
  },
  protectionPolicy: {
    date: '15.01.2021',
    head: 'Индивидуальным предпринимателем Зелениным Иваном Владимировичем ОГРНИП 320784700104332',
  },
  isAddProductActive: true,
};

export const DomainsData = {
  [DomainsKey.nutrify]: <Domain>{
    ...DomainsDefaultData,
    lang: Language.en,
    patientProfileDomain: 'https://www.stage.' + DomainsKey.nutrify,
    meta: {
      title: 'Nutrify',
    },
    domain: DomainsKey.nutrify,
    logo: 'nutrify',
    logoMobile: 'nutrify',
    favicon: 'nutrify',
    isAddProductActive: false,
  },
  [DomainsKey.nutrientplaner]: <Domain>{
    ...DomainsDefaultData,
    domain: DomainsKey.nutrientplaner,
  },
  [DomainsKey.nutrientplannerCom]: <Domain>{
    ...DomainsDefaultData,
    lang: Language.en,
    domain: DomainsKey.nutrientplannerCom,
    patientProfileDomain: 'https://' + DomainsKey.nutrientplannerCom,
    isAddProductActive: false,
  },
  [DomainsKey.nutrientplanerWorld]: <Domain>{
    ...DomainsDefaultData,
    lang: Language.en,
    domain: DomainsKey.nutrientplanerWorld,
    patientProfileDomain: 'https://' + DomainsKey.nutrientplanerWorld,
    isAddProductActive: false,
  },
  [DomainsKey.niap]: <Domain>{
    ...DomainsDefaultData,
    lang: Language.ru,
    logo: 'niap',
    logoMobile: 'niap',
    favicon: 'niap',
    color: DomainColor.SECONDARY,
    name: 'НИАП',
    link: 'https://niap.ion.ru/',
    patientProfileDomain: 'https://' + DomainsKey.niap,
    meta: {
      title: 'НИАП',
      srcBitrix: 'loader_11_ys0ptw',
    },
    instructionLink: 'https://www.youtube.com/playlist?list=PLFuZw-kssFgPheSYyG3qxYXmNnajEm2qT',
    isInstructionLinkActive: true,
    tutorialYoutubeLink: 'nax5yK8FvrY',
    termsOfUse: {
      serviceName: 'НИАП',
      head: 'Настоящие Правила (далее – Правила) являются неотъемлемой частью Договора-оферты и регулируют отношения между ООО "Нутриент Планнер" ОГРН 1207800089200, действующий на основании устава, далее именуемым – Исполнитель, и любым лицом, использующим веб-сервис НИАП (Научный инструмент анализа питания), расположенным в сети Интернет по адресу - https://niap.ion.ru/ (далее – веб-сервис),и именуемым в дальнейшем –Пользователь. Настоящие Правила являются офертой, не требуют двустороннего подписания и действительны в электронном виде с момента их размещения на сайте Исполнителя по адресу - https://niap.ion.ru/polzovatelskoe-soglashenie А факт использования веб-сервиса НИАП любым способом признается полным и безоговорочным согласием Пользователя с Правилами, в том числе, в части предоставления согласия Исполнителю на обработку персональных данных Пользователя на условиях, указанных в разделе 4 Правил, и на получение рассылок. При этом Пользователь подтверждает факт своего совершеннолетия, дееспособности и правоспособности.\n',
      date: '13.06.2023',
    },
    offerAgreement: {
      ...DomainsDefaultData.offerAgreement,
      head: 'ООО "Нутриент Планнер" ОГРН 1207800089200, действующий на основании устава, именуемый в дальнейшем Исполнитель, настоящей публичной офертой в соответствии со ст.437 Гражданского кодекса РФ предлагает любому заинтересованному лицу заключить договор на предоставление доступа к веб-сервису НИАП – научный инструмент анализа питания (далее – веб-сервис, НИАП).',
      informationResourcesLink: 'https://niap.ion.ru',
      references: {
        ...DomainsDefaultData.offerAgreement.references,
        orgn: 'ООО "Нутриент Планнер"\n' + 'ОГРН 1207800089200',
        inn: '7816708023',
      },
      serviceName: 'НИАП',
    },
    protectionPolicy: {
      ...DomainsDefaultData.protectionPolicy,
      head: 'ООО "Нутриент Планнер" ОГРН 1207800089200',
      date: '13.06.2023',
    },
    isAddProductActive: false,
  },
  [DomainsKey.local]: <Domain>{
    ...DomainsDefaultData,
    domain: DomainsKey.local,
    patientProfileDomain: 'http://localhost:4201',
    // lang: Language.ru,
    // logo: 'niap',
    // logoMobile: 'niap',
    // favicon: 'niap',
    // color: DomainColor.SECONDARY,
    // name: 'NIAP',
    // link: 'https://niap.ion.ru/',
    // patientProfileDomain: 'https://' + DomainsKey.niap,
    // meta: {
    //   title: 'NIAP',
    // },
    // instructionLink: 'https://www.youtube.com/playlist?list=PLFuZw-kssFgPheSYyG3qxYXmNnajEm2qT',
    // isInstructionLinkActive: true,
    // tutorialYoutubeLink: 'nax5yK8FvrY',
    // termsOfUse: {
    //   serviceName: 'НИАП',
    //   head: 'Настоящие Правила (далее – Правила) являются неотъемлемой частью Договора-оферты и регулируют отношения между ООО "Нутриент Планнер" ОГРН 1207800089200, действующий на основании устава, далее именуемым – Исполнитель, и любым лицом, использующим веб-сервис НИАП (Научный инструмент анализа питания), расположенным в сети Интернет по адресу - https://niap.ion.ru/ (далее – веб-сервис),и именуемым в дальнейшем –Пользователь. Настоящие Правила являются офертой, не требуют двустороннего подписания и действительны в электронном виде с момента их размещения на сайте Исполнителя по адресу - https://niap.ion.ru/polzovatelskoe-soglashenie А факт использования веб-сервиса НИАП любым способом признается полным и безоговорочным согласием Пользователя с Правилами, в том числе, в части предоставления согласия Исполнителю на обработку персональных данных Пользователя на условиях, указанных в разделе 4 Правил, и на получение рассылок. При этом Пользователь подтверждает факт своего совершеннолетия, дееспособности и правоспособности.\n',
    //   date: '13.06.2023',
    // },
    // offerAgreement: {
    //   ...DomainsDefaultData.offerAgreement,
    //   head: 'ООО "Нутриент Планнер" ОГРН 1207800089200, действующий на основании устава, именуемый в дальнейшем Исполнитель, настоящей публичной офертой в соответствии со ст.437 Гражданского кодекса РФ предлагает любому заинтересованному лицу заключить договор на предоставление доступа к веб-сервису НИАП – научный инструмент анализа питания (далее – веб-сервис, НИАП).',
    //   informationResourcesLink: 'https://niap.ion.ru',
    //   references: {
    //     ...DomainsDefaultData.offerAgreement.references,
    //     orgn: 'ООО "Нутриент Планнер"\n' + 'ОГРН 1207800089200',
    //     inn: '7816708023',
    //   },
    //   serviceName: 'НИАП',
    // },
    // protectionPolicy: {
    //   ...DomainsDefaultData.protectionPolicy,
    //   head: 'ООО "Нутриент Планнер" ОГРН 1207800089200',
    //   date: '13.06.2023',
    // },
    // isAddProductActive: false,
  },
};

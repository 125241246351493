import { createAction, props } from '@ngrx/store';

export const loadDishes = createAction(
  '[Directory / API] Load Dishes'
);
export const loadDishesSuccess = createAction(
  '[Directory / API] Load Dishes Success',
  props<{ dishes: any[] }>()
);
export const loadDishesFailure = createAction(
  '[Directory / API] Load Dishes Failure',
  props<{ error: any }>()
);

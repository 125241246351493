import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as UnitsActions from '../actions/units.actions';
import * as UnitsSelectors from '../selectors/units.selectors';
import { DirectoryService } from '@app/core/services/directory.service';
import * as fromShared from '@app/shared/store';
import { select, Store } from '@ngrx/store';

@Injectable()
export class UnitsEffects {
  loadUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitsActions.loadUnits),
      withLatestFrom(this.store$.pipe(select(UnitsSelectors.selectAllUnits))),
      switchMap(([action, allUnits]) => {
        if (allUnits.length) {
          return of(UnitsActions.loadUnitsSuccess({ units: allUnits }));
        }

        return this.directoryService.loadUnits().pipe(
          map((response) => UnitsActions.loadUnitsSuccess({ units: response })),
          catchError((error) => of(UnitsActions.loadUnitsFailure({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromShared.State>,
    private directoryService: DirectoryService,
  ) {}
}

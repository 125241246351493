import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProductsActions from '../actions/products.actions';
import { DirectoryService } from '@app/core/services/directory.service';

@Injectable()
export class ProductsEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadProducts),
      switchMap(({ type, ...params }) => {
        return this.directoryService.loadProducts(params).pipe(
          map((response) => {
            return ProductsActions.loadProductsSuccess({
              products: response,
            });
          }),
          catchError((error) => of(ProductsActions.loadProductsFailure({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private directoryService: DirectoryService,
  ) {}
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SpinnerComponent implements OnInit {
  @Input() isFullActive = false;
  @Input() width: number;
  @Input() height: number;
  @Input() size: 'small' | 'middle' | 'large' = 'large';

  constructor() {}

  ngOnInit(): void {}
}

import { createAction, props } from '@ngrx/store';

export const loadCookingTypes = createAction(
  '[Directory / API] Load Cooking Types'
);
export const loadCookingTypesSuccess = createAction(
  '[Directory / API] Load Cooking Types Success',
  props<{ cookingTypes: any[] }>()
);
export const loadCookingTypesFailure = createAction(
  '[Directory / API] Load Cooking Types Failure',
  props<{ error: any }>()
);

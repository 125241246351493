import { createSelector } from '@ngrx/store';
import * as fromDietType from '../reducers/diet-type.reducer';
import { selectDietTypeState } from '../reducers';

export const selectDietTypeListIsLoading = createSelector(
  selectDietTypeState,
  fromDietType.selectIsLoading,
);

export const selectDietTypeEntities = createSelector(
  selectDietTypeState,
  fromDietType.selectDietTypeEntities,
);

export const selectAllDietTypes = createSelector(
  selectDietTypeState,
  fromDietType.selectAllDietTypes,
);

export const selectDietTypeById = (id: number) =>
  createSelector(
    selectDietTypeEntities,
    (dietTypeEntities) => dietTypeEntities[id],
  );

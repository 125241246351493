import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as GenderActions from '../actions/gender.actions';
import { Gender } from '@app/shared/models';

export const genderFeatureKey = 'gender';

export interface State extends EntityState<Gender> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<Gender>({
  selectId: (gender: Gender) => gender.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(GenderActions.loadGenderTypes, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(GenderActions.loadGenderTypesSuccess, (state, { genderTypes }) => ({
    ...adapter.setAll(genderTypes, state),
    isLoading: false,
  })),

  on(GenderActions.loadGenderTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectGenderTypeEntities = selectEntities;
export const selectAllGenderTypes = selectAll;

export const selectIsLoading = (state: State) => state.isLoading;

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as MedicamentsActions from '../actions/medicaments.actions';
import { DirectoryService } from '@app/core/services/directory.service';

@Injectable()
export class MedicamentsEffects {
  loadMedicaments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MedicamentsActions.loadMedicaments),
      switchMap(({ type, ...params }) => {
        return this.directoryService.loadMedicaments(params).pipe(
          map((response) => {
            return MedicamentsActions.loadMedicamentsSuccess({
              medicaments: response,
            });
          }),
          catchError((error) => of(MedicamentsActions.loadMedicamentsFailure({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private directoryService: DirectoryService,
  ) {}
}

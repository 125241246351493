import { createAction, props } from '@ngrx/store';

export const loadWorkingGroups = createAction(
  '[Directory / API] Load Working Groups'
);
export const loadWorkingGroupsSuccess = createAction(
  '[Directory / API] Load Working Groups Success',
  props<{ workingGroups: any[] }>()
);
export const loadWorkingGroupsFailure = createAction(
  '[Directory / API] Load Working Groups Failure',
  props<{ error: any }>()
);

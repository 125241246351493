import { createSelector } from '@ngrx/store';
import * as fromProductGroup from '../reducers/product-group.reducer';
import { selectProductGroupListState } from '../reducers';
import { Directory } from '@app/shared/models';
import { excludedHandbook } from '@app/shared/utils/utils';

export const selectAllProductGroupList = createSelector(
  selectProductGroupListState,
  fromProductGroup.selectAll,
);

export const selectExcludedProductGroupList = createSelector(
  selectProductGroupListState,
  fromProductGroup.selectExcludedProductGroupList,
);

export const selectAllProductGroupListConstant = createSelector(
  selectProductGroupListState,
  fromProductGroup.selectAllProductGroupList,
);

export const selectProductGroupNoDisabledList = createSelector(
  selectAllProductGroupListConstant,
  selectExcludedProductGroupList,
  (allProductGroupList: Directory[], excludedProductGroupList: Directory[]): Directory[] => {
    return excludedHandbook(allProductGroupList, excludedProductGroupList);
  }
);

export const selectAllProductGroupListFiltered = createSelector(
  selectAllProductGroupList,
  (allProductGroupList: Directory[]): Directory[] => {
    return allProductGroupList.filter((itemFirst: Directory): boolean => itemFirst.id > -3);
  }
);

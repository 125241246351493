import { createAction, props } from '@ngrx/store';
import { Dictinary, QueryParams } from '@app/shared/models';

export const loadDrinks = createAction(
  '[Directory / API] Load Drinks',
  props<QueryParams>(),
);
export const loadDrinksSuccess = createAction(
  '[Directory / API] Load Drinks Success',
  props<{ drinks: any[] }>(),
);
export const loadDrinksFailure = createAction(
  '[Directory / API] Load Drinks Failure',
  props<{ error: Dictinary[] }>(),
);

export const resetDrinkList = createAction(
  '[Directory] Reset Drink List',
);

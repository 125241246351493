import { Component } from '@angular/core';
import { DomainService } from '@app/core/services/domain.service';
import { OfferAgreement, References } from '@app/shared/models';

@Component({
  selector: 'app-offer-agreement-dialog',
  templateUrl: './offer-agreement-dialog.component.html',
  styleUrls: ['./offer-agreement-dialog.component.scss']
})
export class OfferAgreementDialogComponent {
  constructor(private _domain: DomainService) { }

  public get dataDomain(): any {
    return this._domain.getDataDomain();
  }

  public get offerAgreement(): OfferAgreement {
    return this._domain.getOfferAgreement();
  }

  public get references(): References {
    return this._domain.getOfferAgreement().references;
  }

  public get offerAgreementLink(): string {
    return `${this.dataDomain.link}dogovor-oferty`;
  }

  public get polzovatelskoeSoglashenieLink(): string {
    return `${this.dataDomain.link}polzovatelskoe-soglashenie`;
  }
}

import { createSelector } from '@ngrx/store';
import * as fromUnits from '../reducers/units.reducer';
import { selectDishState } from '../reducers';

export const selectUnitsIsLoading = createSelector(
  selectDishState,
  fromUnits.selectIsLoading,
);

export const selectUnitsEntities = createSelector(
  selectDishState,
  fromUnits.selectUnitsEntities,
);

export const selectAllUnits = createSelector(
  selectDishState,
  fromUnits.selectAllUnits,
);

export const selectUnitById = (id: number) =>
  createSelector(selectUnitsEntities, (unitsEntities) => unitsEntities[id]);

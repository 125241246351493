import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthPageComponent } from '@app/auth/pages/auth-page/auth-page.component';
import { LoggedInGuard } from '@app/core/guards/logged-in.guard';

export const routes: Routes = [
  {
    path: 'auth',
    component: AuthPageComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'login',
        data: { page: 'login-page' },
        loadChildren: () =>
          import('./pages/login-page/login-page.module').then((m) => m.LoginPageModule),
      },
      {
        path: 'registration',
        data: { page: 'registration-page' },
        loadChildren: () =>
          import('./pages/registration-page/registration-page.module').then((m) => m.RegistrationPageModule),
      },
      {
        path: 'email',
        data: { page: 'email-confirmation-page' },
        loadChildren: () =>
          import('./pages/email-confirmation-page/email-confirmation-page.module').then((m) => m.EmailConfirmationPageModule),
      },
      {
        path: 'email/verify',
        data: { page: 'email-confirmation-page/email-verification-page' },
        loadChildren: () =>
          import('./pages/email-verification-page/email-verification-page.module').then((m) => m.EmailVerificationPageModule),
      },
      {
        path: 'reset',
        data: { page: 'reset-password-page' },
        loadChildren: () =>
          import('./pages/reset-password-page/reset-password-page.module').then((m) => m.ResetPasswordPageModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessDialogComponent implements OnInit {
  constructor(public dialogRef: DialogRef) {}

  ngOnInit(): void {}

  acceptAction() {
    this.dialogRef.close(true);
  }
}

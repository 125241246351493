import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthPageComponent } from '@app/auth/pages/auth-page/auth-page.component';
import { FirstVisitDialogModule } from '@app/shared/components/dialogs/first-visit-dialog/first-visit-dialog.module';
import { SuccessDialogModule } from '@app/shared/components/dialogs/success-dialog/success-dialog.module';
import { WarningMessageModule } from '@app/shared/components/warning-message/warning-message.module';
import { AuthRoutingModule } from './auth-routing.module';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
  declarations: [
    AuthPageComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    WarningMessageModule,
    // Dialogs
    FirstVisitDialogModule,
    SuccessDialogModule,
    TranslocoModule
  ],
})
export class AuthModule { }

import { createSelector } from '@ngrx/store';
import * as fromDietRestriction from '../reducers/diet-restriction.reducer';
import { selectDietRestrictionState } from '../reducers';

export const selectDietRestrictionListIsLoading = createSelector(
  selectDietRestrictionState,
  fromDietRestriction.selectIsLoading,
);

export const selectDietRestrictionEntities = createSelector(
  selectDietRestrictionState,
  fromDietRestriction.selectDietRestrictionEntities,
);

export const selectAllDietRestrictions = createSelector(
  selectDietRestrictionState,
  fromDietRestriction.selectAllDietRestrictions,
);

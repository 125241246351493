import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationCounterComponent } from './notification-counter.component';



@NgModule({
  declarations: [NotificationCounterComponent],
  exports: [
    NotificationCounterComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class NotificationCounterModule { }

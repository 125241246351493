import { createSelector } from '@ngrx/store';
import * as fromRoot from '@app/store/selectors/router.selectors';
import * as fromPatient from '../reducers/patient.reducer';
import { selectPatientState } from '@app/profile/store/reducers';

export const selectPatientHash = fromRoot.selectRouteFragment;
export const selectActivePatientPageData = fromRoot.selectRouterParamData('page');

export const selectIsLoading = createSelector(
  selectPatientState,
  fromPatient.selectIsLoading,
);

export const selectIsLoaded = createSelector(
  selectPatientState,
  fromPatient.selectIsLoaded,
);

export const selectPatientId = createSelector(
  selectPatientState,
  fromPatient.selectPatientId,
);

export const selectCurrentPatient = createSelector(
  selectPatientState,
  fromPatient.selectPatient,
);

export const selectCompletedStepsCount = createSelector(
  selectPatientState,
  fromPatient.selectCompletedStepsCount,
);

export const selectProfileInfo = createSelector(
  selectPatientState,
  fromPatient.selectProfileInfo,
);

import { Component, OnInit } from '@angular/core';
import { OfferAgreementDialogComponent } from '@app/shared/components/dialogs/offer-agreement-dialog/offer-agreement-dialog.component';
import { TermsOfUseDialogComponent } from '@app/shared/components/dialogs/terms-of-use-dialog/terms-of-use-dialog.component';
import { DialogService } from '@ngneat/dialog';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { DomainService } from '@app/core/services/domain.service';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'auth',
        alias: 'auth'
      }
    }
  ],
})
export class AuthPageComponent implements OnInit {
  get domainName(): string {
    console.log(this._domain.getDataDomain());
    return this._domain.getDataDomain().name;
  }

  constructor(
    private _dialog: DialogService,
    private _domain: DomainService,
  ) {}

  ngOnInit(): void {}

  openTermsDialog(): void {
    this._dialog.open(TermsOfUseDialogComponent);
  }

  openOfferAgreementDialog(): void {
    this._dialog.open(OfferAgreementDialogComponent);
  }
}

import { createSelector } from '@ngrx/store';
import * as fromCookingType from '../reducers/cooking-type.reducer';
import { selectCookingTypeState } from '../reducers';

export const selectCookingTypeListIsLoading = createSelector(
  selectCookingTypeState,
  fromCookingType.selectIsLoading,
);

export const selectCookingTypesEntities = createSelector(
  selectCookingTypeState,
  fromCookingType.selectEntities,
);

export const selectAllCookingTypes = createSelector(
  selectCookingTypeState,
  fromCookingType.selectAll,
);

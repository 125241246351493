import { createSelector } from '@ngrx/store';
import { selectSportLevelState } from '@app/shared/store/reducers';
import * as fromSportLevel from '@app/shared/store/reducers/sport-level.reducer';

export const selectSportLevelListIsLoading = createSelector(
  selectSportLevelState,
  fromSportLevel.selectIsLoading,
);

export const selectSportLevelEntities = createSelector(
  selectSportLevelState,
  fromSportLevel.selectSportLevelEntities,
);

export const selectSportLevelList = createSelector(
  selectSportLevelState,
  fromSportLevel.selectSportLevelList,
);

export const selectSportLevelById = (id: number) =>
  createSelector(
    selectSportLevelEntities,
    (sportLevelEntities) => sportLevelEntities[id],
  );

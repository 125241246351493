import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as WorkingGroupActions from '../actions/working-group.actions';
import * as WorkingGroupSelectors from '../selectors/working-group.selectors';
import { DirectoryService } from '@app/core/services/directory.service';
import * as fromShared from '@app/shared/store';
import { select, Store } from '@ngrx/store';
@Injectable()
export class WorkingGroupEffects {
  loadWorkingGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkingGroupActions.loadWorkingGroups),
      withLatestFrom(this.store$.pipe(select(WorkingGroupSelectors.selectAllWorkingGroups))),
      switchMap(([action, allWorkingGroups]) => {
        if (allWorkingGroups.length) {
          return of(WorkingGroupActions.loadWorkingGroupsSuccess({ workingGroups: allWorkingGroups }));
        }

        return this.directoryService.loadWorkingGroups().pipe(
          map((response) => WorkingGroupActions.loadWorkingGroupsSuccess({ workingGroups: response })),
          catchError((error) => of(WorkingGroupActions.loadWorkingGroupsFailure({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromShared.State>,
    private directoryService: DirectoryService,
  ) {}
}

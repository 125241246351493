import { createReducer, on, Action } from '@ngrx/store';
import * as PatientActions from '@app/profile/store/actions/patient.actions';
import * as QuestionsActions from '@app/profile/store/actions/questions.actions';
import { Patient } from '@app/shared/models/patient.model';

export const patientFeatureKey = 'patient';

export interface State {
  patient: Patient | null;
  error: string | null;
  isLoading: boolean;
  isLoaded: boolean;
  patientId: number;
  completedStepsCount: number;
  selectedDayId: number | null;
  selectedDayEatingId: string | null;
  profileInfo: any;

}

export const initialState: State = {
  patient: null,
  error: null,
  isLoading: false,
  isLoaded: false,
  patientId: null,
  completedStepsCount: 0,
  selectedDayId: null,
  selectedDayEatingId: null,
  profileInfo: null
};

export const patientReducer = createReducer(
  initialState,

  on(PatientActions.checkPatientHash, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    isLoaded: false,
  })),
  on(PatientActions.checkPatientHashSuccess, (state, { patientId }) => ({
    ...state,
    patientId,
    error: null,
    isLoading: false,
    isLoaded: true,
  })),
  on(PatientActions.checkPatientHashFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
    isLoaded: true,
  })),

  on(PatientActions.checkPatientSecretWord, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    isLoaded: false,
  })),
  on(PatientActions.checkPatientSecretWordSuccess, (state, { patient }) => ({
    ...state,
    patient,
    isLoading: false,
    isLoaded: true,
    error: null,
  })),
  on(PatientActions.checkPatientSecretWordFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
    isLoaded: true,
  })),

  on(PatientActions.updateCurrentPatient, (state, { patient }) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(PatientActions.updateCurrentPatientSuccess, (state, { patient }) => ({
    ...state,
    patient,
    completedStepsCount: Object.keys(patient?.attributes?.data?.questions || {}).length,
    error: null,
    isLoading: false,
  })),
  on(PatientActions.updateCurrentPatientFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  // TODO refactor
  on(QuestionsActions.setQuestionSteps,
    (state) => ({
    ...state,
    completedStepsCount: Object.keys(state.patient?.attributes?.data?.questions || {}).length,
  })),



  on(PatientActions.getProfileInfoFromStorage, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(PatientActions.getProfileInfoFromStorageSuccess, (state, { profileInfo }) => ({
    ...state,
    profileInfo,
    isLoading: false,
    isLoaded: true,
  })),
  on(PatientActions.getProfileInfoFromStorageFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
    isLoaded: true,
  })),

  on(PatientActions.addProfileInfoToStorage, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(PatientActions.addProfileInfoToStorageSuccess, (state, { profileInfo }) => ({
    ...state,
    profileInfo,
    isLoading: false,
  })),
  on(PatientActions.addProfileInfoToStorageFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(PatientActions.loadCurrentPatient, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    isLoaded: false,
  })),
  on(PatientActions.loadCurrentPatientSuccess, (state, { patient }) => ({
    ...state,
    patient,
    isLoading: false,
    isLoaded: true,
    error: null,
  })),
  on(PatientActions.loadCurrentPatientFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
    isLoaded: true,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return patientReducer(state, action);
}

export const selectPatient = (state: State) => state.patient;
export const selectError = (state: State) => state.error;
export const selectIsLoading = (state: State) => state.isLoading;
export const selectIsLoaded = (state: State) => state.isLoaded;
export const selectPatientId = (state: State) => state.patientId;
export const selectCompletedStepsCount = (state: State) => state.completedStepsCount;
export const selectProfileInfo = (state: State) => state.profileInfo;


import { createSelector } from '@ngrx/store';

import { Directory } from '@app/shared/models';
import { excludedHandbook } from '@app/shared/utils/utils';

import { selectDrinkGroupListState } from '../reducers';
import * as fromDrinkGroup from '../reducers/drink-group.reducer';

export const selectAllDrinkGroupList = createSelector(
  selectDrinkGroupListState,
  fromDrinkGroup.selectAll,
);

export const selectExcludedDrinksGroupList = createSelector(
  selectDrinkGroupListState,
  fromDrinkGroup.selectExcludedDrinksGroupList,
);

export const selectAllDrinkGroupListConstant = createSelector(
  selectDrinkGroupListState,
  fromDrinkGroup.selectAllDrinkGroupList,
);

export const selectDrinkGroupNoDisabledList = createSelector(
  selectAllDrinkGroupListConstant,
  selectExcludedDrinksGroupList,
  (allDrinksGroupList: Directory[], excludedDrinksGroupList: Directory[]): Directory[] => {
    return excludedHandbook(allDrinksGroupList, excludedDrinksGroupList);
  }
);

export const selectAllDrinkGroupListFiltered = createSelector(
  selectAllDrinkGroupList,
  (allDrinksGroupList: Directory[]): Directory[] => {
    return allDrinksGroupList.filter((itemFirst: Directory): boolean => itemFirst.id > -3);
  }
);


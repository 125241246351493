import { createAction, props } from '@ngrx/store';
import { Patient } from '@app/shared/models';

export const checkPatientHash = createAction(
  '[Profile Patient / API] Check Patient Hash',
);
export const checkPatientHashSuccess = createAction(
  '[Profile Patient / API] Check Patient Hash Success',
  props<{ patientId: number }>(),
);
export const checkPatientHashFailure = createAction(
  '[Profile Patient / API] Check Patient Hash Failure',
  props<{ error: any }>(),
);

// export const navigateNextQuestion = createAction(
//   '[Profile Patient] Navigate Next Question',
// );

export const checkPatientSecretWord = createAction(
  '[Profile Patient / API] Check Patient Secret Word',
  props<{ secretWord: string }>(),
);
export const checkPatientSecretWordSuccess = createAction(
  '[Profile Patient / API] Check Patient Secret Word Success',
  props<{ patient: Patient }>(),
);
export const checkPatientSecretWordFailure = createAction(
  '[Profile Patient / API] Check Patient Secret Word Failure',
  props<{ error: any }>(),
);

export const updateCurrentPatient = createAction(
  '[Profile Patient / API] Update Current Patient',
  props<{ patient: Patient }>(),
);
export const updateCurrentPatientSuccess = createAction(
  '[Profile Patient / API] Update Current Patient Success',
  props<{ patient: Patient }>(),
);
export const updateCurrentPatientFailure = createAction(
  '[Profile Patient / API] Update Current Patient Failure',
  props<{ error: any }>(),
);

export const getProfileInfoFromStorage = createAction(
  '[Profile Patient / API] Get Profile Info From Storage',
);
export const getProfileInfoFromStorageSuccess = createAction(
  '[Profile Patient / API] Get Profile Info From Storage Success',
  props<{ profileInfo: any }>(),
);
export const getProfileInfoFromStorageFailure = createAction(
  '[Profile Patient / API] Get Profile Info From Storage Failure',
  props<{ error: any }>(),
);

export const addProfileInfoToStorage = createAction(
  '[Profile Patient / API] Add Profile Info To Storage',
  props<{ profileInfo: any }>(),
);
export const addProfileInfoToStorageSuccess = createAction(
  '[Profile Patient / API] Add Profile Info To Storage Success',
  props<{ profileInfo: any }>(),
);
export const addProfileInfoToStorageFailure = createAction(
  '[Profile Patient / API] Add Profile Info To Storage Failure',
  props<{ error: any }>(),
);

export const loadCurrentPatient = createAction(
  '[Profile Patient / API] Load Current Patient',
);
export const loadCurrentPatientSuccess = createAction(
  '[Profile Patient / API] Load Current Patient Success',
  props<{ patient: any }>(),
);
export const loadCurrentPatientFailure = createAction(
  '[Profile Patient / API] Load Current Patient Failure',
  props<{ error: any }>(),
);

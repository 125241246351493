<section class="terms-of-use">
  <h1 class="terms-of-use__title">Правила использования веб-сервиса {{termsOfUse.serviceName}}</h1>
  <div class="terms-of-use__content">
    <div class="terms-of-use__content-text">{{ termsOfUse.head }}</div>
    <div class="terms-of-use__content-text">
      <strong class="terms-of-use__subtitle">1. Общие положения</strong>
      <p class="terms-of-use__text">
        1.1. Исполнитель предоставляет Пользователю возможность пользоваться веб-сервисом «как есть» («as is») и не несет ответственности за любые убытки, включая упущенную выгоду, связанную с использованием или невозможностью использования какого-либо инструмента, отдельной функции или всего веб-сервиса.
      </p>
      <p class="terms-of-use__text">
        1.2. Пользователь принимает на себя ответственность и все риски, связанные с использованием веб-сервиса, самостоятельно несет перед третьими лицами ответственность за законность любых собственных действий в связи с использованием веб-сервиса, а также за правильность и безопасность документов (рационов, рецептов, отчетов и иных документов), составленных им в веб-сервисе.
      </p>
      <p class="terms-of-use__text">
        1.3. Исполнитель не участвует в составлении Пользователем документов (рационов, рецептов, отчетов и иных документов), не несет ответственности за правильность и безопасность их составления Пользователем и не гарантирует качество и полноту составленных Пользователемдокументов (рационов, отчетов и иных документов).
      </p>
      <p class="terms-of-use__text">
        1.4. Веб-сервис и доменное имя
        <a
          class="terms-of-use__text-link"
          [href]="dataDomain.link"
          target="_blank"
        >
          {{ dataDomain.link }}
        </a>
        принадлежат Исполнителю на праве собственности.
      </p>
    </div>
    <div class="terms-of-use__content-text">
      <strong class="terms-of-use__subtitle">2. Правовой статус Исполнителя</strong>
      <p class="terms-of-use__text">
        2.1. Исполнитель осуществляет контроль за функционированием веб-сервиса, его работоспособностью, действиями Пользователя в процессе использования им веб-сервиса.
      </p>
      <p class="terms-of-use__text">
        2.2. Исполнитель вправе:
      </p>
      <p class="terms-of-use__text">
        2.2.1. Ограничить доступ к веб-сервису Пользователю, который нарушает условия и требования настоящих Правил и других документов, которые регулируют порядок использования веб-сервиса, без финансовой компенсации.
      </p>
      <p class="terms-of-use__text">
        2.2.2. В любое время изменять оформление веб-сервиса, его содержание, список инструментов и платных функций, изменять или дополнять используемое программное обеспечение и другие объекты, используемые или хранящиеся в нем, любые серверные приложения, любой контент в любое время с предварительным уведомлением или без такового.
      </p>
      <p class="terms-of-use__text">
        2.2.3. В любое время изменить функциональные возможности или закрыть любой из инструментов веб-сервиса без предварительного уведомления и в этом случае Исполнитель не несет никакой ответственности за прекращение доступа к таким инструментам или функциям.
      </p>
      <p class="terms-of-use__text">
        2.2.4. В любое время изменить Правила в одностороннем порядке без предварительного уведомления Пользователя. При этом, новая редакция Правил вступает в силу с момента ее опубликования на сайте Исполнителя по адресу -
        <a
          class="terms-of-use__text-link"
          [href]="polzovatelskoeSoglashenieLink"
          target="_blank"
        >
          {{ polzovatelskoeSoglashenieLink}}
        </a>
      </p>
      <p class="terms-of-use__text">
        2.2.5. Отправлять Пользователю по электронной почте или другими доступными способами уведомления (сообщения), касающиеся использования веб-сервиса.
      </p>
      <p class="terms-of-use__text">
        2.2.6. Осуществлять иные действия с целью улучшения качества и удобства использования Пользователем веб-сервиса.
      </p>
      <p class="terms-of-use__text">
        2.3. Исполнитель обязуется:
      </p>
      <p class="terms-of-use__text">
        2.3.1.Организовать и обеспечить надлежащее оказание услуг Пользователю.
      </p>
      <p class="terms-of-use__text">
        2.3.2. Оказывать техническую поддержку, предоставлять устные и письменные консультации Пользователю по вопросам, которые могут возникнуть у последнего в процессе использования веб-сервиса.
      </p>
    </div>
    <div class="terms-of-use__content-text">
      <strong class="terms-of-use__subtitle">3. Правовой статус Пользователя:</strong>
      <p class="terms-of-use__text">
        3.1. После прохождения процедуры регистрации в веб-сервисе, Пользователю присваивается логин и пароль, необходимые для авторизации в веб-сервисе. С указанного момента он получает доступ к веб-сервису.
      </p>
      <p class="terms-of-use__text">
        3.2. При использовании веб-сервиса Пользователь обязуется:
      </p>
      <p class="terms-of-use__text">
        3.2.1. Соблюдать Правила, а также условия других документов, которые регулируют отношения между Исполнителем и Пользователем и функционирование веб-сервиса.
      </p>
      <p class="terms-of-use__text">
        3.2.2. Не использовать услуги, предоставляемые Исполнителем, в противоправных целях или в целях, которые могут каким-либо образом нанести ущерб веб-сервису, Исполнителю и/или третьим лицам.
      </p>
      <p class="terms-of-use__text">
        3.2.3. Не разглашать конфиденциальную информацию, которая стала известна Пользователю в связи с использованием веб-сервиса.
      </p>
      <p class="terms-of-use__text">
        3.2.4. Не нарушать общепринятые в сети интернет правила цитирования, копирования и распространения контента.
      </p>
      <p class="terms-of-use__text">
        3.2.5. Регулярно отслеживать информацию об изменений Правил.
      </p>
      <p class="terms-of-use__text">
        3.2.6. Не размещать в веб-сервисе и на информационных ресурсах и не направлять куда-либо через/посредством веб-сервиса или информационных ресурсов любые материалы следующего характера:
        - нарушающие законодательство, содержащие угрозы и оскорбления, дискредитирующие других лиц, нарушающие права граждан на частную жизнь или публичный порядок, носящие характер непристойности;
        - нарушающие в той или иной степени честь и достоинство, права и охраняемые законом интересы других лиц;
        - способствующие или содержащие призывы к разжиганию религиозной, расовой или межнациональной розни, содержащие попытки разжигания вражды или призывы к насилию;
        - а также иные материалы, которые побуждают других лиц на противоправное поведение, влекущее уголовную, гражданско-правовую и иную ответственность или каким-либо образом нарушающее положения законодательства.
      </p>
      <p class="terms-of-use__text">
        3.2.7. Не размещать в веб-сервисе и на информационных ресурсах и не направлять через/посредством веб-сервиса и информационных ресурсов материалы, являющиеся рекламой каких-либо товаров или услуг, без получения предварительного явно выраженного согласия Исполнителя.
      </p>
      <p class="terms-of-use__text">
        3.2.8. Не использовать веб-сервис и информационные ресурсы для рекламы или иного стимулирования сбыта любых товаров и услуг в любой форме, включая, но не ограничиваясь, стимулирование остальных Пользователей к подписке на другой аналогичный веб-сервис, являющийся конкурентом Исполнителя.
      </p>
      <p class="terms-of-use__text">
        3.2.9. Не загружать, размещать или иным образом использовать в веб-сервисе и на информационных ресурсах какие-либо материалы, охраняемые законодательством об интеллектуальной собственности (в том числе, авторским правом, законодательством о товарных знаках), и иные охраняемые законодательством материалы без получения выраженного разрешения обладателя прав на охраняемый материал. При этом бремя доказывания того, что размещение в веб-сервисе и на информационных ресурсахПользователем материалов не нарушает авторские, смежные и иные права третьих лиц на размещаемые материалы, а также ответственность незаконное размещение лежит на Пользователе.
      </p>
      <p class="terms-of-use__text">
        3.2.8. Не модифицировать, не продавать, не распространять этот контент и программы, целиком либо по частям. Использовать веб-сервис только в законных целях.
      </p>
      <p class="terms-of-use__text">
        3.3. При использовании веб-сервиса Пользователю запрещено:
      </p>
      <p class="terms-of-use__text">
        3.3.1. Совершать действия, мешающие нормальному функционированию веб-сервиса.
      </p>
      <p class="terms-of-use__text">
        3.3.2. Совершать попытки несанкционированного доступа к управлению веб-сервисом (его закрытыми разделами).
      </p>
      <p class="terms-of-use__text">
        3.3.3. Использовать автоматизированные скрипты (программы) для сбора информации и/или взаимодействия с веб-сервисом.
      </p>
      <p class="terms-of-use__text">
        3.3.4. Любым способом, в том числе путем взлома, пытаться получить доступ к чужой учетной записи вопреки воле владельца записи.
      </p>
      <p class="terms-of-use__text">
        3.4. Пользователь вправе отказаться от принятия изменений Правил, осуществленных Исполнителем в порядке, установленном п.2.2.4. Правил, что означает отказ Пользователя от использования веб-сервиса.
      </p>
    </div>
    <div class="terms-of-use__content-text">
      <strong class="terms-of-use__subtitle">4. Конфиденциальность персональных данных</strong>
      <p class="terms-of-use__text">
        4.1. Исполнитель гарантирует сбор, обработку и хранение персональных данных Пользователей в строгом соответствии с требованиями ФЗ РФ «О персональных данных» и приложением («Политика защиты персональных данных») к Договору-оферте на предоставление доступа к веб-сервису {{ dataDomain.name }}.
      </p>
    </div>
    <div class="terms-of-use__content-text">
      <strong class="terms-of-use__subtitle">5. Ответственность</strong>
      <p class="terms-of-use__text">5.1. Исполнитель не несет ответственности за:</p>
      <p class="terms-of-use__text">
        5.1.1. Временные сбои и перерывы в  работе веб-сервиса и вызванные им потери информации, а также сохранность информации, правильность и своевременность ее передачи и доставки в связи с временными сбоями и перерывами.
      </p>
      <p class="terms-of-use__text">
        5.1.2. Надежность, качество и скорость работы веб-сервиса, сохранность создаваемой, используемой и получаемой Пользователем информации в рамках веб-сервиса.
      </p>
      <p class="terms-of-use__text">
        5.1.3. Сбои, возникающие в сетях электросвязи и/или энергетических сетях, действие вредоносных программ, а также недобросовестные действия лиц, направленные на несанкционированный доступ и/или выведение из строя программного и/или аппаратного комплекса, повлекших за собой недоступность веб-сервиса или незаконный доступ, удаление или модификацию информации, хранящейся в рамках веб-сервиса
      </p>
      <p class="terms-of-use__text">
        5.1.4. Надежность, качество и скорость работы каналов связи, а также доступность сторонних сервисов, принадлежащих третьим лицам и задействованных в работе веб-сервиса.
      </p>
      <p class="terms-of-use__text">
        5.1.5. Правильность функционирования программного и/или аппаратного обеспечения, созданного третьими лицами и используемого при работе с веб-сервисом.
      </p>
      <p class="terms-of-use__text">5.1.6. За отзывы опубликованные в веб-сервисе и на информационных ресурсах.</p>
      <p class="terms-of-use__text">
        5.1.7. За соблюдение/несоблюдение Пользователями своих обязательств перед третьими лицами, достоверность информации, правильность и безопасность составленных ими в веб-сервисе документов (рационов, отчетов и т.д.).
      </p>
      <p class="terms-of-use__text">
        5.2. За нарушение условий Договора-оферты и настоящих Правил доступ Пользователя к веб-сервису или его отдельным разделам может быть ограничен, приостановлен или прекращен на неопределенный срок.
      </p>
      <p class="terms-of-use__text">
        5.3. Пользователь несет ответственность за безопасность своего логина и пароля, а также за все, что будет сделано в веб-сервисе под его логином и паролем. О любом случае неавторизованного (не разрешенного) доступа со своим логином и паролем и/или о любом нарушении безопасности Пользователь обязан немедленно уведомить Исполнителя по электронной почте info@nplanner.ru

        Исполнитель не несет ответственности за потерю (утечку, кражу, копирование и т.д.) данных о Пользователе и третьих лицах, размещенных Пользователем в веб-сервисе, в случае нарушения Пользователем указанного пункта, в том числе в случае потери или передачи Пользователем третьим лицам своего логина и/или пароля.
      </p>
    </div>
    <div class="terms-of-use__content-text">
      <strong class="terms-of-use__subtitle">6. Использование материалов</strong>
      <p class="terms-of-use__text">
        6.1. Веб-сервис,  контент и информационные ресурсы являются собственностью Исполнителя. Они охраняются авторским правом, как произведение созданное в соответствии с законодательством РФ об авторском праве и смежных правах.
      </p>
      <p class="terms-of-use__text">
        6.2. Исполнителю принадлежит авторское право на использование содержания веб-сервиса и информационных ресурсов (в том числе, право на подбор, расположение, систематизацию и преобразование данных, содержащихся на веб-сервисе и информационных ресурсах, а также на сами исходные данные), кроме случаев, отдельно отмеченных в содержании опубликованных материалов.
      </p>
      <p class="terms-of-use__text">
        6.3. Любое использование информации, размещаемой, распространяемой и предоставляемой в веб-сервисе и информационных ресурсах Исполнителя или с их помощью разрешается при условии соблюдения действующего законодательства, условий Договора-оферты, настоящих Правил и других официальных документов Компании.
      </p>
      <p class="terms-of-use__text">
        6.4. Запрещается копирование, воспроизведение, переиздание, выгрузка, размещение, передача, распространение информации, содержащейся в веб-сервисе и информационных ресурсах Исполнителя, ее использование для создания производных продуктов и контента без получения предварительного письменного согласия от Исполнителя, за исключением того, что Исполнитель предоставил неисключительное, не подлежащее передаче другим лицам,  разрешение на использование веб-сервиса на условиях, изложенных в Договоре-оферте, настоящих Правилах и иных правовых документах, применимых к сторонам.
      </p>
      <p class="terms-of-use__text">
        6.5. Пользователь соглашается с тем, что добавляемые им в веб-сервис рецепты, становятся доступными для использования другими Пользователями сразу после их добавления. Если Пользователь хочет ограничить доступ остальных Пользователей к добавляемым им рецептам, то он может письменно уведомить об этом Исполнителя.
      </p>
      <p class="terms-of-use__text">
        6.6. Все ссылки на веб-сервис и информационные ресурсы Исполнителя должны быть утверждены Исполнителем в письменной форме, за исключением того, что Исполнитель дает согласие в отношении ссылок, в которых ссылка и страницы, активируемые этой ссылкой:
      </p>
      <p class="terms-of-use__text">
        6.6.1. Не создают фреймов вокруг каких-либо страниц веб-сервиса ина информационных ресурсах Исполнителя и не используют никаких иных методов, которые бы каким-либо иным образом изменяли визуальное представление или внешний вид контента.
      </p>
      <p class="terms-of-use__text">
        6.6.2. Не вводят в заблуждение относительно правоотношений и взаимосвязи Пользователя и Исполнителя.
      </p>
      <p class="terms-of-use__text">
        6.6.3. Не подразумевают, что Исполнитель выражает свое одобрение или дает рекомендации относительно Пользователя, его сайта и предложений об услугах или продуктах Исполнителя.
      </p>
      <p class="terms-of-use__text">
        6.6.4. Не создают ложное или дезориентирующее впечатление о веб-сервисе/Исполнителе или иным образом не наносят ущерба репутации веб-сервису/Исполнителю.
      </p>
      <p class="terms-of-use__text">
        6.7. В качестве дополнительного условия получения разрешения на создание ссылок на веб-сервис и информационные ресурсы Исполнителя, Пользователь согласен с тем, что Исполнитель вправе в любой момент исключительно по своему усмотрению аннулировать разрешение на создание ссылок. При подобных обстоятельствах Пользователь обязуется незамедлительно удалить все ссылки.
      </p>
      <p class="terms-of-use__text">
        6.8. Для правильного и надлежащего использования функций веб-сервиса, составления с его помощью правильных и безопасных документов (рационов, отчетов и т.д.) Пользователю необходимо обладать квалификацией и профессиональными знаниями в области питания и медицины.
      </p>
      <p class="terms-of-use__text">
        6.9. При отсутствии у Пользователя соответствующей квалификации и профессиональных знаний, документы (рационы, отчеты и др. документы), составленные им с помощью веб-сервиса, перед их использованием подлежат утверждению у специализирующегося врача. Пользователь, нарушивший это обязательство, принимает на себя все риски и последствия, связанные с таким нарушением.
      </p>
    </div>
    <p class="terms-of-use__content-text">
      Редакция от {{ termsOfUse.date }} г.
    </p>
  </div>
</section>

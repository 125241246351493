import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as DietRestrictionActions from '../actions/diet-restriction.actions';
import { DirectoryService } from '@app/core/services/directory.service';
import {
  select,
  Store,
} from '@ngrx/store';
import * as DietRestrictionsSelects from '@app/shared/store/selectors/diet-restriction.selectors';

@Injectable()
export class DietRestrictionEffects {
  loadFiltersProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DietRestrictionActions.loadDietRestriction),
      withLatestFrom(
        this.store$.pipe(
          select(DietRestrictionsSelects.selectAllDietRestrictions),
        ),
      ),
      switchMap(([action, dietRestrictionList]) => (
          dietRestrictionList.length
            ? of(dietRestrictionList)
            : this.directoryService.loadDietRestrictions()
        )
          .pipe(
            map((dietRestrictions) => DietRestrictionActions.loadDietRestrictionSuccess({ dietRestrictions })),
            catchError((error) => of(DietRestrictionActions.loadDietRestrictionFailure({ error }))),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private directoryService: DirectoryService,
    private store$: Store
  ) {}
}

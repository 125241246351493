<section
  *transloco="let t; read: 'firstVisitDialog'"
  class="first-visit-dialog"
>
  <h1 class="first-visit-dialog__title">
    {{ t('welcome', { name: domainName }) }}
  </h1>
  <div class="first-visit-dialog__content">
    <p class="first-visit-dialog__subtitle">
      <strong class="first-visit-dialog__subtitle-attention">{{ t('attention') }}</strong>
      {{ t('features-are-available-trial-version') }}
    </p>
    <ul class="first-visit-dialog__list">
      <li class="first-visit-dialog__item">{{ t('reporting') }}</li>
      <li class="first-visit-dialog__item">{{ t('ration-creation') }}</li>
      <li class="first-visit-dialog__item">{{ t('time-changes') }}</li>
      <li class="first-visit-dialog__item">{{ t('group-selection') }}</li>
      <li class="first-visit-dialog__item">{{ t('create-your-templates') }}</li>
    </ul>
    <!-- <div class="first-visit-dialog__content-bottom">
      <p class="first-visit-dialog__text">Оформите, пожалуйста, подписку для получения доступа к этим функциям.</p>
      <p class="first-visit-dialog__text">Перед началом работы рекомендуем ознакомиться с инструкциями.</p>
    </div> -->
  </div>
  <footer class="first-visit-dialog__footer">
    <a
      class="first-visit-dialog__button"
      (click)="closeDialog()"
    >
      {{ 'button.i-get-it' | transloco }}
    </a>
    <!-- <button
      class="first-visit-dialog__button first-visit-dialog__button--close"
      (click)="closeDialog()"
    >
      Я уже ознакомлен
    </button> -->
  </footer>
</section>

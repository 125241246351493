import { Language } from '@app/shared/constants/language';
import { DomainsKey } from '@app/shared/enums/domains';

export interface Domain {
  lang: Language;
  domain?: DomainsKey;
  logo: string;
  logoMobile: string;
  favicon: string;
  color: DomainColor;
  name: string;
  link: string;
  patientProfileDomain: string;
  meta: {
    title: string;
    srcBitrix: string;
  }
  isInstructionLinkActive: boolean;
  instructionLink: string;
  tutorialYoutubeLink: string;
  address: {
    googleLink: string;
    street: string;
  };
  termsOfUse: TermsOfUse;
  offerAgreement: OfferAgreement;
  protectionPolicy: ProtectionPolicy;
  isAddProductActive: boolean;
}

export interface TermsOfUse {
  head: string;
  date: string;
  serviceName: string;
}

export interface OfferAgreement {
  head: string;
  '1.2': string;
  informationResourcesLink: string;
  references: References;
  serviceName: string;
}

export interface References {
  orgn: string;
  inn: string;
  pc: string;
  bank: string;
  bik: string;
  ks: string;
  postalAddress: string;
  email: string;
  phone: string;
}

export interface ProtectionPolicy {
  date: string;
  head: string;
}

export enum DomainColor {
  DEFAULT = 'background-default',
  SECONDARY = 'background-secondary-brand'
}

<div class="svg-icon">
  <svg
    class="svg-icon__svg"
    [style.width.px]="width"
    [style.height.px]="height"
    [style.color]="fill"
  >
    <use attr.xlink:href="assets/sprites/sprite.svg#{{ icon }}"></use>
  </svg>
</div>

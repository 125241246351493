import { Dictinary } from '@app/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as DietTypeActions from '../actions/diet-type.actions';

export const dietTypeFeatureKey = 'diet-type';

export interface State extends EntityState<Dictinary> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<Dictinary> = createEntityAdapter<Dictinary>({
  selectId: (dietType: Dictinary) => dietType.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const workingGroupReducer = createReducer(
  initialState,

  on(DietTypeActions.loadDietTypes, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(DietTypeActions.loadDietTypesSuccess, (state, { dietTypes }) => ({
    ...adapter.setAll(dietTypes, state),
    isLoading: false,
  })),

  on(DietTypeActions.loadDietTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return workingGroupReducer(state, action);
}

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDietTypeEntities = selectEntities;
export const selectAllDietTypes = selectAll;

export const selectIsLoading = (state: State) => state.isLoading;

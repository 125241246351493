import { Component } from '@angular/core';
import { DomainService } from '@app/core/services/domain.service';
import { TermsOfUse } from '@app/shared/models';

@Component({
  selector: 'app-terms-of-use-dialog',
  templateUrl: './terms-of-use-dialog.component.html',
  styleUrls: ['./terms-of-use-dialog.component.scss']
})
export class TermsOfUseDialogComponent {
  constructor(private _domain: DomainService) { }

  public get dataDomain(): any {
    return this._domain.getDataDomain();
  }

  public get termsOfUse(): TermsOfUse {
    return this._domain.getTermsDomain();
  }

  public get polzovatelskoeSoglashenieLink(): string {
    return `${this.dataDomain.link}polzovatelskoe-soglashenie`;
  }
}

<div
  class="spinner"
  [class.spinner--full]="isFullActive"
  [class]="'spinner--size-' + size"
>
  <div
    class="spinner__loader"
    [style.width.px]="width"
    [style.height.px]="height"
  ></div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './core/guards/auth.guard';
import { ProfileGuard } from './core/guards/profile.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'dashboard',
    data: { page: 'dashboard' },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/core/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'profile',
    data: { page: 'profile' },
    canActivate: [ProfileGuard],
    loadChildren: () =>
      import('@app/profile/profile.module').then((m) => m.ProfileModule),
  },
  // !Исключаем из загузки
  // {
  //   path: 'ui-kits',
  //   data: { page: 'ui-kits' },
  //   loadChildren: () =>
  //     import('@app/shared/components/ui-kits/ui-kits.module').then((m) => m.UiKitsModule),
  // },
  // TODO NOT FOUND Component 404
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

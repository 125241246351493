import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-counter',
  templateUrl: './notification-counter.component.html',
  styleUrls: ['./notification-counter.component.scss']
})
export class NotificationCounterComponent implements OnInit {
  @Input() counter: number;

  constructor() { }

  ngOnInit(): void {
  }

}

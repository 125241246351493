import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as RecipeGroupActions from '../actions/recipe-group.actions';
import { Directory } from '@app/shared/models';

export const recipeGroupFeatureKey = 'recipe-group';

export interface State extends EntityState<Directory> {
  isLoading: boolean;
}
export const adapter: EntityAdapter<Directory> = createEntityAdapter<Directory>({
  selectId: (directoryItem: Directory) => directoryItem.id,
  sortComparer: false,
});
export const initialState: State = adapter.getInitialState({
  isLoading: false
});

export const reducer = createReducer(
  initialState,

  on(RecipeGroupActions.loadRecipeGroup, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RecipeGroupActions.loadRecipeGroupSuccess, (state, { recipeGroup }) => ({
    ...adapter.setAll(recipeGroup, state),
    isLoading: false,
  })),
  on(RecipeGroupActions.loadRecipeGroupFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(RecipeGroupActions.clearRecipeGroup, (state) => ({
    ...adapter.removeAll({...state}),
  })),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;

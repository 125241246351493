export const environment = {
  production: true,
  name: 'testing',
  primaryUrl: 'https://dev.nutrientplaner.ru:8000',
  primaryApiUrl: 'https://dev.nutrientplaner.ru/api',
  primaryApiDomain: 'dev.nutrientplaner.ru:8000',
  domain: 'dev.nutrientplaner.ru',
  patientProfileDomain: 'dev.nutrientplaner.ru',
  sentry: 'https://7b509325224d488b946964dd8d02aaa5@sentry.nutrientplaner.ru/1',
  apmlitude: null,  
  log: {
    routing: false,
    level: 'debug',
    loggers: {},
  },
  googleTagManager: {
    id: 'GTM-5VVK2GK',
  },
  googleAnalytics: {
    id: 'G-CX1QQCK5VN',
  },
  yandexMetrika: {
    id: 70489375,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
  experrto: {
    id: '4cbbd5f5339767a8c91ff4932393291a06a497e0',
  },
  locales: ['ru', 'en'],
  defaultLocale: 'ru',
  fallbackLang: 'ru'
};

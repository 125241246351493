import { SearchList } from '@app/shared/models/patient/search-list.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as DrinksActions from '../actions/drinks.actions';

export const featureKey = 'drinks';

export interface State extends EntityState<SearchList> {
  isLoading: boolean;
}
export const adapter: EntityAdapter<SearchList> = createEntityAdapter<SearchList>(
  {
    selectId: (item: SearchList) => item.id,
    sortComparer: false,
  },
);
export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,
  on(DrinksActions.loadDrinks, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(DrinksActions.loadDrinksSuccess, (state, { drinks }) => ({
    ...adapter.setAll(drinks, state),
    isLoading: false,
  })),

  on(DrinksActions.loadDrinksFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(DrinksActions.resetDrinkList, (state) => ({
    ...adapter.removeAll(state),
  })),
);

export const { selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;

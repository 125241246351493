import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DrinksActions from '../actions/drinks.actions';
import { DirectoryService } from '@app/core/services/directory.service';

@Injectable()
export class DrinksEffects {
  loadDrinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrinksActions.loadDrinks),
      switchMap(({ type, ...params }) => {
        return this.directoryService.loadDrinks(params).pipe(
          map((response) => {
            return DrinksActions.loadDrinksSuccess({
              drinks: response,
            });
          }),
          catchError((error) => of(DrinksActions.loadDrinksFailure({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private directoryService: DirectoryService,
  ) {}
}

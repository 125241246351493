import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as GenderActions from '../actions/gender.actions';
import * as GenderSelectors from '../selectors/gender.selectors';
import { DirectoryService } from '@app/core/services/directory.service';
import * as fromShared from '@app/shared/store';
import { select, Store } from '@ngrx/store';
@Injectable()
export class GenderEffects {
  loadGenderTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenderActions.loadGenderTypes),
      withLatestFrom(this.store$.pipe(select(GenderSelectors.selectAllGenderTypes))),
      switchMap(([action, allGenderTypes]) => {
        if (allGenderTypes.length) {
          return of(GenderActions.loadGenderTypesSuccess({ genderTypes: allGenderTypes }));
        }
        return this.directoryService.loadGenderTypes().pipe(
          map((response) => GenderActions.loadGenderTypesSuccess({ genderTypes: response })),
          catchError((error) => of(GenderActions.loadGenderTypesFailure({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromShared.State>,
    private directoryService: DirectoryService,
  ) {}
}

import { createAction, props } from '@ngrx/store';

export const loadDietTypes = createAction(
  '[Directory / API] Load Diet Types'
);
export const loadDietTypesSuccess = createAction(
  '[Directory / API] Load Diet Types Success',
  props<{ dietTypes: any[]}>()
);
export const loadDietTypesFailure = createAction(
  '[Directory / API] Load Diet Types Failure',
  props<{ error: any }>()
);

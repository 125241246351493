<div class="auth"  *transloco="let t; read: 'auth'" >
  <ul class="auth__list">
    <li class="auth__item">
      <a
        class="auth__link"
        [routerLink]="['/auth/login']"
        routerLinkActive="auth__link--active"
      >
        {{t('signin')}}
      </a>
    </li>
    <li class="auth__item">
      <a
        class="auth__link"
        [routerLink]="['/auth/registration']"
        routerLinkActive="auth__link--active"
      >
        {{t('signup')}}
      </a>
    </li>
  </ul>
  <div class="auth__body">
    <app-warning-message
      class="auth__warning-message"
      [warningMessage]="[t('comfort-work', { name: domainName })]"
    ></app-warning-message>

    <div class="auth__content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="auth__footer">
    <button
      class="auth__button-dialog"
      (click)="openTermsDialog()"
    >
      {{t('terms-of-use')}}
    </button>

    <button
      class="auth__button-dialog"
      (click)="openOfferAgreementDialog()"
    >
      {{t('agreement-offer')}}
    </button>
  </div>
</div>

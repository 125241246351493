import { createReducer, on } from '@ngrx/store';
import { Dictinary } from '@app/shared/models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as DiseasesActions from '../actions/diseases.actions';

export const featureKey = 'diseases';

export interface State extends EntityState<Dictinary> {
  isLoading: boolean;
}
export const adapter: EntityAdapter<Dictinary> = createEntityAdapter<Dictinary>({
  selectId: (item: Dictinary) => item.id,
  sortComparer: false,
});
export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,
  on(DiseasesActions.loadDiseases, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(DiseasesActions.loadDiseasesSuccess, (state, { diseases }) => ({
    ...adapter.setAll(diseases, state),
    isLoading: false,
  })),

  on(DiseasesActions.loadDiseasesFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;

import { createAction, props } from '@ngrx/store';

export const loadUnits = createAction(
  '[Directory / API] Load Units'
);
export const loadUnitsSuccess = createAction(
  '[Directory / API] Load Units Success',
  props<{ units: any[] }>()
);
export const loadUnitsFailure = createAction(
  '[Directory / API] Load Units Failure',
  props<{ error: any }>()
);

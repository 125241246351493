import { createAction, props } from '@ngrx/store';
import { Dictinary } from '@app/shared/models';

export const loadDrinkGroup = createAction(
  '[Directory / API] Load Drink Group'
);
export const loadDrinkGroupSuccess = createAction(
  '[Directory / API] Load Drink Group Success',
  props<{ drinkGroup: any[] }>()
);
export const loadDrinkGroupFailure = createAction(
  '[Directory / API] Load Drink Group Failure',
  props<{ error: any }>()
);

export const clearDrinkGroup = createAction(
  '[Directory] Clear Drink Group',
);

export const addExcludedDrinkGroup = createAction(
  '[Directory] Add Excluded Drink Group',
  props<{ excludedDrinkGroup: Dictinary }>()
);

export const removeExcludedDrinkGroup = createAction(
  '[Directory] Remove Excluded Drink Group',
  props<{ removeExcludedDrinkGroup: Dictinary }>()
);

export const addExcludedDrinkGroupFromSelect = createAction(
  '[Directory] Add Excluded Drink Group From Select',
  props<{ excludedDrinkGroup: Dictinary }>()
);

export const removeExcludeDrinkGroupFromSelect = createAction(
  '[Directory] Remove Exclude Drink Group From Select',
  props<{ excludedDrinkGroup: Dictinary }>()
);

import { createSelector } from '@ngrx/store';
import * as fromDiseases from '../reducers/diseases.reducer';
import { selectDiseasesState } from '../reducers';

export const selectDiseaseListIsLoading = createSelector(
  selectDiseasesState,
  fromDiseases.selectIsLoading,
);

export const selectAllDiseases = createSelector(
  selectDiseasesState,
  fromDiseases.selectAll,
);

import { Food } from '@app/shared/models/ration/food.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ProductsActions from '../actions/products.actions';

export const featureKey = 'products';

export interface State extends EntityState<Food> {
  isLoading: boolean;
}
export const adapter: EntityAdapter<Food> = createEntityAdapter<Food>({
  selectId: (item: any) => item.id,
  sortComparer: false,
});
export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,
  on(ProductsActions.loadProducts, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ProductsActions.loadProductsSuccess, (state, { products }) => ({
    ...adapter.setAll(products, state),
    isLoading: false,
  })),

  on(ProductsActions.loadProductsFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(ProductsActions.resetProductList, (state) => ({
    ...adapter.removeAll(state),
  })),
);

export const { selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;

<div
  class="loader"
  [class.loader--full]="isFullActive"
  [class.loader--full-small]="isFullSmall"
  [class.loader--in-flow]="isInFlowActive"
  [class.loader--middle]="isMiddleActive"
>
  <div class="loader__content">
    <div
      class="loader__animation"
      [class.loader__animation--full]="isFullActive"
    >
      <div
        class="loader__inner"
        [class.loader__inner--full]="isFullActive"
      ></div>
    </div>

    <div
      class="loader__indicator"
      *ngIf="isIndicatorActive"
    ></div>

    <p
      class="loader__text"
      *ngIf="isLoaderTextActive"
    >
      {{ 'messages.loading' | transloco }}
    </p>
  </div>
</div>

import { createSelector } from '@ngrx/store';
import { selectAuthState } from '../reducers';
import * as fromAuth from '../reducers/auth.reducer';
import * as fromRoot from '@app/store';
import * as fromShared from '@app/shared/store';
import * as RouterSelectors from '../selectors/router.selectors';
import { Patient, User } from '@app/shared/models';

export const selectActiveVerifyEmailId = RouterSelectors.selectRouterQueryParam('id');
export const selectActiveVerifyEmailHash = RouterSelectors.selectRouterQueryParam('hash');
export const selectActiveVerifyEmailExpires = RouterSelectors.selectRouterQueryParam('expires');
export const selectActiveVerifyEmailSignature = RouterSelectors.selectRouterQueryParam('signature');

export const selectActiveResetPasswordToken = RouterSelectors.selectRouterQueryParam('token');
export const selectActiveResetPasswordEmail = RouterSelectors.selectRouterQueryParam('email');

export const selectIsLoading = createSelector(
  selectAuthState,
  fromAuth.selectIsLoading,
);

export const selectIsLoaded = createSelector(
  selectAuthState,
  fromAuth.selectIsLoaded,
);

export const selectError = createSelector(
  selectAuthState,
  fromAuth.selectError,
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  fromAuth.selectIsAuthenticated,
);

export const selectCurrentUser = createSelector(
  selectAuthState,
  fromAuth.selectUser,
);

export const selectIsCurrentUserTrial = createSelector(
  selectCurrentUser,
  (currentUser: User) => {
    return currentUser?.user_subscription.is_trial;
  }
);

export const selectCurrentPatient = createSelector(
  selectAuthState,
  fromAuth.selectPatient,
);

export const selectCurrentPatientGenderType = createSelector(
  fromShared.selectGenderTypeEntities,
  selectCurrentPatient,
  (genderTypeEntities, currentPatient: Patient) => {
    return genderTypeEntities[currentPatient.attributes.gender];
  }
);

export const selectCurrentPatientDietType = createSelector(
  fromShared.selectDietTypeEntities,
  selectCurrentPatient,
  (dietTypeEntities, currentPatient: Patient) => {
    return dietTypeEntities[currentPatient.attributes.diet_type];
  }
);

export const selectCurrentPatientDietRestriction = createSelector(
  fromShared.selectDietRestrictionEntities,
  selectCurrentPatient,
  (dietRestrictionEntities, currentPatient: Patient) => {
    if (currentPatient.attributes.data.diet_restrictions) {
      return currentPatient.attributes.data.diet_restrictions.map((item) => dietRestrictionEntities[item]);
    }
  }
);

export const selectCurrentPatientWorkingGroup = createSelector(
  fromShared.selectWorkingGroupEntities,
  selectCurrentPatient,
  (workingGroupEntities, currentPatient: Patient) => {
    return workingGroupEntities[currentPatient.attributes.working_group];
  }
);

export const selectCurrentPatientSportLevel = createSelector(
  fromShared.selectSportLevelEntities,
  selectCurrentPatient,
  (sportLevelEntities, currentPatient: Patient) => {
    return sportLevelEntities[currentPatient.attributes.data.sport];
  }
);

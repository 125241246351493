import { createSelector } from '@ngrx/store';
import * as fromProducts from '../reducers/products.reducer';
import { selectProductsState } from '../reducers';

export const selectProductListIsLoading = createSelector(
  selectProductsState,
  fromProducts.selectIsLoading,
);

export const selectAllProducts = createSelector(
  selectProductsState,
  fromProducts.selectAll,
);

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { take, map, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromProfile from '@app/profile/store';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard implements CanActivate {
  constructor(
    private store: Store<fromProfile.State>,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    // this.store.dispatch(fromProfile.checkPatientHash());
    this.store.dispatch(fromProfile.getProfileInfoFromStorage());

    return combineLatest([
      this.store.pipe(select(fromProfile.selectProfileInfo)),
      this.store.pipe(select(fromProfile.selectPatientId)),
      this.store.pipe(select(fromProfile.selectIsLoaded)),
    ]).pipe(
      filter(([profileInfo, patientId, isLoaded]) => isLoaded),
      map(([profileInfo, patientId, isLoaded]) => {
        if (profileInfo || patientId) {
          return true;
        } else {
          // this.router.navigate([`/profile/questions-greeting#${profileInfo.hash}`]);
          this.router.navigate(['/profile/questions-greeting']);
          return false;
        }
      }),
      take(1),
    );
  }
}

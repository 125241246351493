import { createSelector } from '@ngrx/store';
import * as fromWorkingGroup from '../reducers/working-group.reducer';
import { selectWorkingGroupState } from '../reducers';

export const selectWorkingGroupListIsLoading = createSelector(
  selectWorkingGroupState,
  fromWorkingGroup.selectIsLoading,
);

export const selectWorkingGroupEntities = createSelector(
  selectWorkingGroupState,
  fromWorkingGroup.selectWorkingGroupEntities,
);

export const selectAllWorkingGroups = createSelector(
  selectWorkingGroupState,
  fromWorkingGroup.selectAllWorkingGroups,
);

export const selectWorkingGroupById = (id: number) =>
  createSelector(
    selectWorkingGroupEntities,
    (workingGroupEntities) => workingGroupEntities[id],
  );

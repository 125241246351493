import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as fromShared from '@app/shared/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DirectoryService } from '@app/core/services/directory.service';
import { of } from 'rxjs';
import * as SportLevelActions from '@app/shared/store/actions/sport-level.actions';
import * as SportLevelSelectors from '@app/shared/store/selectors/sport-level.selectors';

@Injectable()
export class SportLevelEffects {
  loadSportLevelList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportLevelActions.loadSportLevelList),
      withLatestFrom(
        this.store$.pipe(select(SportLevelSelectors.selectSportLevelList)),
      ),
      switchMap(([action, currentSportLevelList]) =>
        (currentSportLevelList.length
          ? of(currentSportLevelList)
          : this.directoryService.loadSportLevelList()
        ).pipe(
          map((sportLevelList: any[]) =>
            SportLevelActions.loadSportLevelListSuccess({ sportLevelList }),
          ),
          catchError((error) =>
            of(SportLevelActions.loadSportLevelListFailure({ error })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromShared.State>,
    private directoryService: DirectoryService,
  ) {}
}

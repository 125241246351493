import { createSelector } from '@ngrx/store';
import { selectFoodListState } from '../reducers';
import * as fromFoodList from '@app/shared/store/reducers/food-list.reducer';

export const selectFoodList = createSelector(
  selectFoodListState,
  (state: fromFoodList.State) => state.foodList,
);

export const selectFoodListIsLoading = createSelector(
  selectFoodListState,
  (state: fromFoodList.State) => state.isLoading
);

import { Dictinary, QueryParams } from '@app/shared/models';
import { createAction, props } from '@ngrx/store';

export const loadDiseases = createAction(
  '[Directory / API] Load Diseases',
  props<QueryParams>(),
);
export const loadDiseasesSuccess = createAction(
  '[Directory / API] Load Diseases Success',
  props<{ diseases: Dictinary[] }>(),
);
export const loadDiseasesFailure = createAction(
  '[Directory / API] Load Diseases Failure',
  props<{ error: any }>(),
);
export const resetDiseasesList = createAction(
  '[Food List] Reset Diseases List',
);

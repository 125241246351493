import { createSelector } from '@ngrx/store';
import * as fromDrinks from '../reducers/drinks.reducer';
import { selectDrinksState } from '../reducers';

export const selectDrinkListIsLoading = createSelector(
  selectDrinksState,
  fromDrinks.selectIsLoading,
);

export const selectAllDrinks = createSelector(
  selectDrinksState,
  fromDrinks.selectAll,
);

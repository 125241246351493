import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { DomainService } from '@app/core/services/domain.service';

@Component({
  selector: 'app-first-visit-dialog',
  templateUrl: './first-visit-dialog.component.html',
  styleUrls: ['./first-visit-dialog.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dialogs/firstVisitDialog',
        alias: 'firstVisitDialog'
      }
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FirstVisitDialogComponent implements OnInit {
  get domainName(): string {
    return this._domain.getDataDomain().name;
  }

  constructor(
    public dialogRef: DialogRef,
    private _domain: DomainService,
    // public experrtoService: ExperrtoService,
  ) {}

  ngOnInit(): void {}

  closeDialog(): void {
    this.dialogRef.close(true);

    // if (environment.production) {
      // this.experrtoService.identify({ email: this.currentUser.user_profile.email });
      // this.experrtoService.show();
    // }
  }
}

import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Directory } from '@app/shared/models';
import { removeDuplicatesArr } from '@app/shared/utils/utils';

import * as DrinkGroupActions from '../actions/drink-group.actions';

export const drinkGroupFeatureKey = 'drink-group';

export interface State extends EntityState<Directory> {
  isLoading: boolean;
  excludedDrinksGroupList: Directory[];
  allDrinkGroupList: Directory[];
}
export const adapter: EntityAdapter<Directory> = createEntityAdapter<Directory>({
  selectId: (directoryItem: Directory) => directoryItem.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  excludedDrinksGroupList: [],
  allDrinkGroupList: [],
});

export const reducer = createReducer(
  initialState,

  on(DrinkGroupActions.loadDrinkGroup, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DrinkGroupActions.loadDrinkGroupSuccess, (state, { drinkGroup }) => ({
    ...adapter.setAll(drinkGroup, state),
    isLoading: false,
    allDrinkGroupList: drinkGroup,
  })),
  on(DrinkGroupActions.loadDrinkGroupFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(DrinkGroupActions.clearDrinkGroup, (state) => ({
    ...adapter.removeAll({...state}),
  })),

  on(DrinkGroupActions.addExcludedDrinkGroup, (state, { excludedDrinkGroup }) => ({
    ...state,
    excludedDrinksGroupList: removeDuplicatesArr([...state.excludedDrinksGroupList, excludedDrinkGroup]),
    ...adapter.removeOne(excludedDrinkGroup.id, state)
  })),

  on(DrinkGroupActions.removeExcludedDrinkGroup, (state, { removeExcludedDrinkGroup }) => {
    const excludedDrinksGroupList = state.excludedDrinksGroupList.filter(itemFilter => itemFilter.id !== removeExcludedDrinkGroup.id)
    return {
      ...state,
      excludedDrinksGroupList,
      ...adapter.upsertOne(removeExcludedDrinkGroup, state)
    }
  }),

  on(DrinkGroupActions.addExcludedDrinkGroupFromSelect, (state, { excludedDrinkGroup }) => ({
    ...state,
    excludedDrinksGroupList: removeDuplicatesArr([...state.excludedDrinksGroupList, excludedDrinkGroup]),
  })),

  on(DrinkGroupActions.removeExcludeDrinkGroupFromSelect, (state, { excludedDrinkGroup }) => ({
    ...state,
    excludedDrinksGroupList: state.excludedDrinksGroupList.filter(itemFilter => itemFilter.id !== excludedDrinkGroup.id),
  })),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
export const selectExcludedDrinksGroupList = (state: State) => state.excludedDrinksGroupList;
export const selectAllDrinkGroupList = (state: State) => state.allDrinkGroupList;

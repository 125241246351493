import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonBackgroundColor, ButtonType, ButtonTypeView, SpinnerSize } from '@app/shared/enums/common';
import { DomainService } from '@app/core/services/domain.service';
import { DomainColor } from '@app/shared/models';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ButtonComponent implements OnInit {
  @Input() type: ButtonType = ButtonType.Button;
  @Input() typeView: ButtonTypeView = ButtonTypeView.Default;
  @Input() backgroundColor: ButtonBackgroundColor | DomainColor = ButtonBackgroundColor.BackgroundDefault;
  @Input() text = '';
  @Input() nowrap = false;
  @Input() notification = false;
  @Input() notificationCounter: number;
  @Input() minWidthDefaultActive = false;
  @Input() svgIcon = '';
  @Input() svgIconActive = '';
  @Input() svgFill = '';
  @Input() svgWidth: number;
  @Input() svgHeight: number;
  @Input() svgClassActive: string;
  @Input() disabled = false;
  @Input() isLoading = false;
  @Input() spinnerSize: SpinnerSize = SpinnerSize.Middle;
  @Output() buttonClick = new EventEmitter();

  constructor(private _domain: DomainService) {}

  ngOnInit(): void {}

  onButtonClick(event): void {
    this.buttonClick.emit(event);
  }

  getTypeViewClass(): string {
    if (
      this.typeView === ButtonTypeView.Simple ||
      this.typeView === ButtonTypeView.SimpleTextIcon ||
      this.typeView === ButtonTypeView.Close
    ) {
      return ButtonBackgroundColor.BackgroundNone;
    } else {
      return this.backgroundColor || ButtonBackgroundColor.BackgroundDefault;
    }
  }
}

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@app/auth/auth.module';
import { JwtModule } from '@auth0/angular-jwt';
import { tokenGetter } from './core/services/auth.service';
import { environment } from '@env/environment';
import { DialogModule } from '@ngneat/dialog';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
// !NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PreloadingModule } from './shared/preloading/preloading.module';
import { effects, metaReducers, ROOT_REDUCERS } from './store';
import * as fromRouter from './store/reducers/router.reducer';
// !End NGRX
//
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CleanHttpParamsInterceptor } from './core/interceptors/clean-http-params.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
// !ServiceWorkerModule
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgSelectModule } from '@ng-select/ng-select';

// !Sentry
import * as Sentry from '@sentry/angular';
// !Analytics
// !Amplitude
import { TranslocoRootModule } from './transloco-root.module';

// let instance1 = amplitude.getInstance().init(environment.apmlitude);
// Sentry.init({
//   dsn: environment.sentry,
//   environment: environment.name,
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: [
//         environment.primaryApiUrl,
//         environment.patientProfileDomain,
//       ],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // !AuthModule
    AuthModule,
    // !AppRoutingModule
    AppRoutingModule,
    // !DialogModule
    DialogModule.forRoot(),
    // !NgSelectModule,
    NgSelectModule,
    // !Quicklink
    PreloadingModule,
    // !Toast
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    // !JWT
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [environment.primaryApiDomain, environment.domain],
        disallowedRoutes: [
          `${environment.domain}/auth/`,
          `${environment.domain}/profile/`,
        ],
        authScheme: 'bearer ',
        skipWhenExpired: true,
      },
    }),
    // !NGRX
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      name: 'NGRX Nutrient Planner Store App',
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: fromRouter.routerFeatureKey,
      serializer: fromRouter.CustomSerializer,
    }),
    EffectsModule.forRoot(effects),
    // !ServiceWorker
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    TranslocoRootModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    // !Global DOCUMENT
    {
      provide: Document,
      useExisting: DOCUMENT,
    },
    // !HTTP_INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ResponseInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CleanHttpParamsInterceptor,
      multi: true,
    },
    // !Sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotificationCounterModule } from '@app/shared/components/notification-counter/notification-counter.module';
import { SpinnerModule } from '@app/shared/loaders/spinner/spinner.module';
import { TooltipModule } from 'ng2-tooltip-directive';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { ButtonComponent } from './button.component';

@NgModule({
  declarations: [
    ButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SvgIconModule,
    NotificationCounterModule,
    SpinnerModule,
    TooltipModule,
  ],
  exports: [
    ButtonComponent
  ]
})
export class ButtonModule { }

import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as DishActions from '../actions/dish.actions';

export const dishFeatureKey = 'dish';

export interface State extends EntityState<any> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (dish: any) => dish.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(DishActions.loadDishes, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(DishActions.loadDishesSuccess, (state, { dishes }) => ({
    ...adapter.setAll(dishes, state),
    isLoading: false,
  })),

  on(DishActions.loadDishesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectGenderTypeEntities = selectEntities;
export const selectAllGenderTypes = selectAll;

export const selectIsLoading = (state: State) => state.isLoading;

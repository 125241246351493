import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrowserStorageService } from '@app/core/services/browser-storage.service';
import { Token, UserCredentials } from '@app/shared/models';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResetPasswordModel } from '../../shared/models/reset-password.model';

export const TOKEN_KEY = 'nutrient-planer-auth-token';

export function tokenGetter() {
  return localStorage.getItem(TOKEN_KEY);
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token = tokenGetter();

  constructor(
    private http: HttpClient,
    private jwtHelperService: JwtHelperService,
    private browserStorageService: BrowserStorageService,
  ) {}

  login(userCredentials: Partial<UserCredentials>): Observable<any> {
    return this.http
      .post<any>(`${environment.primaryApiUrl}/auth/login`, userCredentials, {
        params: { ...userCredentials },
      })
      .pipe(tap((response: Token) => this.checkToken(response)));
  }

  registration(userCredentials: Partial<UserCredentials>): Observable<any> {
    return this.http.post(
      `${environment.primaryApiUrl}/auth/registration`,
      userCredentials,
    );
  }

  logout(): Observable<any> {
    return this.http.post(`${environment.primaryApiUrl}/auth/logout`, {});
  }

  checkEmail(email): Observable<any> {
    return this.http.post(`${environment.primaryApiUrl}/auth/email`, email);
  }

  resetPassword(resetPassword: Partial<ResetPasswordModel>): Observable<any> {
    return this.http.post(
      `${environment.primaryApiUrl}/auth/reset`,
      resetPassword,
    );
  }

  verifyEmail(verifyEmail: any): Observable<any> {
    return this.http
      .get(
        `${environment.primaryApiUrl}/auth/email/verify/${verifyEmail.id}/${verifyEmail.hash}`,
        {
          params: {
            signature: verifyEmail.signature,
            expires: verifyEmail.expires,
          },
        },
      )
      .pipe(tap((response: Token) => this.checkToken(response)));
  }

  clearSession(): void {
    this.removeToken();
  }

  isAuthenticated(): Observable<boolean> {
    return of(!this.isTokenExpired());
  }

  private checkToken(response: Token): void {
    if (response.access_token) {
      this.setToken(response.access_token);
    } else {
      this.removeToken();
    }
  }

  private setToken(token: string): void {
    this.token = token;
    this.browserStorageService.set(TOKEN_KEY, token);
  }

  private removeToken(): void {
    this.token = null;
    this.browserStorageService.remove(TOKEN_KEY);
  }

  private isTokenExpired(): boolean {
    return this.jwtHelperService.isTokenExpired(this.token);
  }

  private getTokenExpirationDate(): Date | Promise<Date> {
    return this.jwtHelperService.getTokenExpirationDate();
  }

  private decodeToken(): any {
    return this.jwtHelperService.decodeToken(this.token);
  }
}

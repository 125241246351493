import { createAction, props } from '@ngrx/store';

export const setQuestionSteps = createAction(
  '[Profile Questions] Set Question Steps',
);

export const setQuestionStepsComplete = createAction(
  '[Profile Questions] Set Question Steps Complete',
);

export const setCurrentQuestionStep = createAction(
  '[Profile Questions] Set Current Question Step',
  props<{ selectedQuestionId: number }>()
);

export const changeValidationStatus = createAction(
  '[Profile Questions] Change Validation Status',
  props<{ isValid: boolean }>()
);

export const navigateCurrentQuestion = createAction(
  '[Profile Questions] Navigate Current Question',
);
export const navigateNextQuestion = createAction(
  '[Profile Questions] Navigate Next Question',
);
export const navigatePreviousQuestion = createAction(
  '[Profile Questions] Navigate Previous Question',
);

export const openCompleteDialog = createAction(
  '[Profile Questions] Open Complete Dialog',
);

export const checkCompletedSteps = createAction(
  '[Profile Questions] Check Completed Steps',
);


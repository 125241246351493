import { Dictinary } from '@app/shared/models';
import { Medicament } from '@app/shared/models/profile/medicament.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as MedicamentsActions from '../actions/medicaments.actions';

export const featureKey = 'medicaments';

export interface State extends EntityState<Medicament> {
  isLoaded: boolean;
  isLoading: boolean;
}
export const adapter: EntityAdapter<Medicament> = createEntityAdapter<Medicament>({
  selectId: (item: Dictinary) => item.id,
  sortComparer: false,
});
export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
});

export const reducer = createReducer(
  initialState,
  on(MedicamentsActions.loadMedicaments, (state) => ({
    ...state,
    isLoaded: false,
    isLoading: true,
  })),

  on(MedicamentsActions.loadMedicamentsSuccess, (state, { medicaments }) => ({
    ...adapter.setAll(medicaments, state),
    isLoaded: true,
    isLoading: false,
  })),

  on(MedicamentsActions.loadMedicamentsFailure, (state) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
  })),
);

export const { selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
export const selectIsLoaded = (state: State) => state.isLoaded;

export enum ButtonType {
  Button = 'button',
  Submit = 'submit'
}

export enum ButtonTypeView {
  Default ='default',
  DefaultRectangle = 'default-rectangle',
  DefaultIcon = 'default-icon',
  DefaultLongIcon = 'default-long-icon',
  DefaultRound = 'default-round',
  DefaultHint = 'default-hint',
  Close = 'close',
  Small = 'small',
  Simple = 'simple',
  SimpleTextIcon = 'simple-text-icon',
}

export enum ButtonBackgroundColor {
  BackgroundDefault ='background-default',
  BackgroundSecondary ='background-secondary',
  BackgroundSimple ='background-simple',
  BackgroundAttention ='background-attention',
  BackgroundIconAttention ='background-icon-attention',
  BackgroundTariffPrimary ='background-tariff-primary',
  BackgroundTariffSecondary ='background-tariff-secondary',
  BackgroundSecondaryBrand ='background-secondary-brand',
  BackgroundNone ='background-none',
}

export enum SpinnerSize {
  Small = 'small',
  Middle = 'middle',
  Large = 'large',
}

export enum TargetType {
  Blank = '_blank',
  Self = '_self',
  Parent = '_parent',
  Top = '_top',
}

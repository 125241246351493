import {Injectable} from '@angular/core';
import {DomainService} from '@app/core/services/domain.service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  get _colorDomain(): any {
    return this._domain.getColoredDomain();
  }

  constructor(
    private _toastrService: ToastrService,
    private _domain: DomainService,
  ) {}

  private multipleErrorToast(error: any): void {
    Object.keys(error).forEach((prop: string) => this._toastrService.error(error[prop]));
  }

  error(error: any): void {
    if (error.errors) {
      this.multipleErrorToast(error.errors);
    }

    this._toastrService.error(error);
  }

  success(success: any): void {
    this._toastrService.success(success, '', {
      toastClass: `${this._colorDomain} ngx-toastr`,
    })
  }
}

import { createAction, props } from '@ngrx/store';

export const loadProducts = createAction(
  '[Directory / API] Load Products',
  props<{ search?: string, limit?: '50', general?: boolean }>(),
);
export const loadProductsSuccess = createAction(
  '[Directory / API] Load Products Success',
  props<{ products: any[] }>(),
);
export const loadProductsFailure = createAction(
  '[Directory / API] Load Products Failure',
  props<{ error: any }>(),
);
export const resetProductList = createAction(
  '[Directory] Reset Product List',
);

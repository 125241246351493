<section class="success-dialog">
  <header class="success-dialog__header">
    <h2 class="success-dialog__title">{{ 'dialog.new-version-available' | transloco }}</h2>
  </header>

  <div class="success-dialog__action">
    <app-button
      class="success-dialog__button"
      [text]="'button.update' | transloco"
      [minWidthDefaultActive]="true"
      (buttonClick)="acceptAction()"
    ></app-button>
  </div>
</section>

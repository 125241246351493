import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Patient } from '@app/shared/models';
import { PatientFilter } from '@app/shared/models/patient-filter.model';
import { NutritionalUnit } from '@app/shared/models/product/nutritional-unit.model';
import { SyncHealthMetricsResponse } from '@app/information/models/sync-health-metrics-response.model';

@Injectable({
  providedIn: 'root',
})

export class PatientsService {
  constructor(private http: HttpClient) {}

  loadPatients(patientFilter: PatientFilter): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/file-cabinet`, {
      params: {
        search: patientFilter.query,
        gender: patientFilter.gender,
        birthday_from: patientFilter.birthdayFrom,
        birthday_to: patientFilter.birthdayTo,
        registration_date_from: patientFilter.registrationDateFrom,
        registration_date_to: patientFilter.registrationDateTo,
        last_appointment_date_from: patientFilter.lastAppointmentDateFrom,
        last_appointment_date_to: patientFilter.lastAppointmentDateTo,
      },
    });
  }

  addPatient(patient): Observable<any> {
    return this.http.post<any>(`${environment.primaryApiUrl}/file-cabinet`, patient);
  }

  removePatient(patientId): Observable<any> {
    return this.http.delete<any>(`${environment.primaryApiUrl}/file-cabinet/${patientId}`);
  }

  selectPatientById(patientId): Observable<any> {
    return this.http.put<any>(`${environment.primaryApiUrl}/file-cabinet/select`, { id: patientId });
  }

  checkPatientHash(hash): Observable<any> {
    return this.http.post<any>(`${environment.primaryApiUrl}/patient/validate-link/`, { hash });
  }

  checkPatientSecretWord(secretWord: string, patientId: any): Observable<any> {
    return this.http.post<any>(`${environment.primaryApiUrl}/patient/${patientId}/validate-secret-word/`,
        { 'secret-word': secretWord },
      )
      .pipe(map((response) => response.data[0]));
  }

  updatePatient(patient: Patient): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/patient/${patient.id}/update`,
      patient,
    );
  }

  getSyncHealthMetrics(id: number): Observable<SyncHealthMetricsResponse> {
    return this.http.get<{data: SyncHealthMetricsResponse}>(
      `${environment.primaryApiUrl}/patient/${id}/sync-health-metrics`
    ).pipe(map(({ data }) => data));
  }

// Patient norms
  loadPatientNutrientNorms(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/norms`);
  }

  updatePatientNutrientNorms(nutrient: NutritionalUnit): Observable<any> {
    const payload = {
      data: {
        ...nutrient
      },
    };
    return this.http.post<any>(`${environment.primaryApiUrl}/norms/update`, payload);
  }

  refreshPatientNutrientNorms(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/norms/refresh`);
  }
}

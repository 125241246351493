import { createSelector } from '@ngrx/store';
import * as fromRecipeGroup from '../reducers/recipe-group.reducer';
import { selectRecipeGroupListState } from '../reducers';

export const selectIsRecipeGroupListLoading = createSelector(
  selectRecipeGroupListState,
  fromRecipeGroup.selectIsLoading,
);

export const selectAllRecipeGroupList = createSelector(
  selectRecipeGroupListState,
  fromRecipeGroup.selectAll,
);

import { createAction, props } from '@ngrx/store';
import { Dictinary, QueryParams } from '@app/shared/models';

export const loadDietRestriction = createAction(
  '[Directory / API] Load load Diet Restriction'
);

export const loadDietRestrictionSuccess = createAction(
  '[Directory / API] Load load Diet Restriction Success',
  props<{ dietRestrictions: Dictinary[] }>(),
);

export const loadDietRestrictionFailure = createAction(
  '[Directory / API] Load load Diet Restriction Failure',
  props<{ error: any }>(),
);

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SvgIconComponent implements OnInit {
  @Input() icon: string;
  @Input() fill: string;
  @Input() width: number;
  @Input() height: number;
  @Input() classes = '';
  @Input() isLoading: boolean;
  @Input() isSpriteActive = true;

  constructor() {}

  ngOnInit(): void {}
}

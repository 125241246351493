import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as ProductGroupActions from '../actions/product-group.actions';
import { Directory } from '@app/shared/models';
import { removeDuplicatesArr } from '@app/shared/utils/utils';

export const productGroupFeatureKey = 'product-group';

export interface State extends EntityState<Directory> {
  isLoading: boolean;
  allProductGroupList: Directory[];
  excludedProductGroupList: Directory[];
}

export const adapter: EntityAdapter<Directory> = createEntityAdapter<Directory>({
  selectId: (directoryItem: Directory) => directoryItem.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  allProductGroupList: [],
  excludedProductGroupList: [],
});

export const reducer = createReducer(
  initialState,

  on(ProductGroupActions.loadProductGroup, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ProductGroupActions.loadProductGroupSuccess, (state, { productGroup }) => ({
    ...adapter.setAll(productGroup, state),
    isLoading: false,
    allProductGroupList: productGroup,
  })),
  on(ProductGroupActions.loadProductGroupFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(ProductGroupActions.clearProductGroup, (state) => ({
    ...adapter.removeAll({...state}),
  })),

  on(ProductGroupActions.addExcludedProductGroup, (state, { excludedProductGroup }) => ({
    ...state,
    excludedProductGroupList: removeDuplicatesArr([...state.excludedProductGroupList, excludedProductGroup]),
    ...adapter.removeOne(excludedProductGroup.id, state)
  })),

  on(ProductGroupActions.removeExcludedProductGroup, (state, { removeExcludedProductGroup }) => {
    const excludedProductGroupList = state.excludedProductGroupList.filter(itemFilter => itemFilter.id !== removeExcludedProductGroup.id)
    return {
      ...state,
      excludedProductGroupList,
      ...adapter.upsertOne(removeExcludedProductGroup, state)
    }
  }),

  on(ProductGroupActions.addExcludedProductGroupFromSelect, (state, { excludedProductGroup }) => ({
    ...state,
    excludedProductGroupList: removeDuplicatesArr([...state.excludedProductGroupList, excludedProductGroup]),
  })),

  on(ProductGroupActions.removeExcludeProductGroupFromSelect, (state, { excludedProductGroup }) => ({
    ...state,
    excludedProductGroupList: state.excludedProductGroupList.filter(itemFilter => itemFilter.id !== excludedProductGroup.id),
  })),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
export const selectExcludedProductGroupList = (state: State) => state.excludedProductGroupList;
export const selectAllProductGroupList = (state: State) => state.allProductGroupList;

import { CookingTypeEffects } from './cooking-type.effects';
import { DietRestrictionEffects } from './diet-restriction.effects';
import { DietTypeEffects } from './diet-type.effects';
import { DiseasesEffects } from './diseases.effects';
import { GenderEffects } from './gender.effects';
import { MedicamentsEffects } from './medicaments.effects';
import { ProductsEffects } from './products.effects';
import { DrinksEffects } from './drinks.effects';
import { WorkingGroupEffects } from './working-group.effects';
import { SportLevelEffects } from './sport-level.effects';
import { FoodListEffects } from './food-list.effects';
import { DrinkGroupEffects } from './drink-group.effects';
import { ProductGroupEffects } from './product-group.effects';
import { RecipeGroupEffects } from './recipe-group.effects';
import { DishEffects } from './dish.effects';
import { UnitsEffects } from './units.effects';

export const effects: any[] = [
  CookingTypeEffects,
  DietTypeEffects,
  DiseasesEffects,
  GenderEffects,
  DietRestrictionEffects,
  MedicamentsEffects,
  ProductsEffects,
  WorkingGroupEffects,
  DrinksEffects,
  SportLevelEffects,
  FoodListEffects,
  DrinkGroupEffects,
  ProductGroupEffects,
  RecipeGroupEffects,
  DishEffects,
  UnitsEffects
];

export * from './cooking-type.effects';
export * from './diet-restriction.effects';
export * from './diet-type.effects';
export * from './diseases.effects';
export * from './gender.effects';
export * from './medicaments.effects';
export * from './products.effects';
export * from './drinks.effects';
export * from './working-group.effects';
export * from './sport-level.effects';
export * from './food-list.effects';
export * from './drink-group.effects';
export * from './product-group.effects';
export * from './recipe-group.effects';
export * from './dish.effects';
export * from './units.effects';

import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as SportLevelActions from '@app/shared/store/actions/sport-level.actions';

export const sportLevelFeatureKey = 'sport-level';

export interface State extends EntityState<any> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (sportLevel: any) => sportLevel.id,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const sportLevelReducer = createReducer(
  initialState,

  on(SportLevelActions.loadSportLevelList, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(SportLevelActions.loadSportLevelListSuccess, (state, { sportLevelList }) =>
    adapter.setAll(sportLevelList, {
      ...state,
      isLoading: false,
    }),
  ),

  on(SportLevelActions.loadSportLevelListFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return sportLevelReducer(state, action);
}

export const {
  selectAll: selectSportLevelList,
  selectEntities: selectSportLevelEntities,
} = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;

import { createSelector } from '@ngrx/store';
import * as fromDish from '../reducers/dish.reducer';
import { selectDishState } from '../reducers';

export const selectDishesIsLoading = createSelector(
  selectDishState,
  fromDish.selectIsLoading,
);

export const selectDishEntities = createSelector(
  selectDishState,
  fromDish.selectGenderTypeEntities,
);

export const selectAllDishes = createSelector(
  selectDishState,
  fromDish.selectAllGenderTypes,
);

export const selectDishById = (id: number) =>
  createSelector(selectDishEntities, (dishEntities) => dishEntities[id]);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { DirectoryService } from '@app/core/services/directory.service';
import {
  loadFoodList,
  loadFoodListFailure,
  loadFoodListSuccess,
} from '@app/shared/store/actions';

@Injectable()
export class FoodListEffects {
  loadFoodList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFoodList),
      switchMap(({ type, ...params }) => {
        return this.directoryService.loadFoodList(params).pipe(
          map((response) => {
            return loadFoodListSuccess({
              foodList: response,
            });
          }),
          catchError((error) => of(loadFoodListFailure({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private directoryService: DirectoryService,
  ) {}
}

import { createAction, props } from '@ngrx/store';
import { Gender } from '@app/shared/models';

export const loadGenderTypes = createAction(
  '[Directory / API] Load Gender Types'
);
export const loadGenderTypesSuccess = createAction(
  '[Directory / API] Load Gender Types Success',
  props<{ genderTypes: Gender[] }>()
);
export const loadGenderTypesFailure = createAction(
  '[Directory / API] Load Gender Types Failure',
  props<{ error: any }>()
);

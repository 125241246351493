import { createSelector } from '@ngrx/store';
import * as fromMedicaments from '../reducers/medicaments.reducer';
import { selectMedicamentsState } from '../reducers';

export const selectMedicamentListIsLoading = createSelector(
  selectMedicamentsState,
  fromMedicaments.selectIsLoading,
);

export const selectAllMedicaments = createSelector(
  selectMedicamentsState,
  fromMedicaments.selectAll,
);

import { QueryParams } from '@app/shared/models';
import { Medicament } from '@app/shared/models/profile/medicament.model';
import { createAction, props } from '@ngrx/store';

export const loadMedicaments = createAction(
  '[Directory / API] Load Medicaments',
  props<QueryParams>(),
);
export const loadMedicamentsSuccess = createAction(
  '[Directory / API] Load Medicaments Success',
  props<{ medicaments: Medicament[] }>(),
);
export const loadMedicamentsFailure = createAction(
  '[Directory / API] Load Medicaments Failure',
  props<{ error: any }>(),
);
export const resetMedicamentsList = createAction(
  '[Directory] Reset Medicaments List',
);

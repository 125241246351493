import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as CookingTypeActions from '../actions/cooking-type.actions';

export const cookingTypeFeatureKey = 'cooking-type';

export interface State extends EntityState<any> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (cookingType: any) => cookingType.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(CookingTypeActions.loadCookingTypes, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(CookingTypeActions.loadCookingTypesSuccess, (state, { cookingTypes }) => {
    return {
      ...adapter.setAll(cookingTypes, state),
      isLoading: false,
    };
  }),

  on(CookingTypeActions.loadCookingTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LoaderComponent implements OnInit {
  @Input() isFullActive = true;
  @Input() isIndicatorActive = false;
  @Input() isLoaderTextActive = false;
  @Input() isFullSmall = false;
  @Input() isInFlowActive = false;
  @Input() isMiddleActive = false;

  constructor() {}

  ngOnInit(): void {}
}

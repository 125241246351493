import {
  combineReducers,
  Action,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromRoot from '@app/store';
import * as fromPatient from './patient.reducer';
import * as fromQuestions from './questions.reducer';

export const profileFeatureKey = 'profile';

export interface State extends fromRoot.State {
  [fromPatient.patientFeatureKey]: fromPatient.State;
  [fromQuestions.questionsFeatureKey]: fromQuestions.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    [fromPatient.patientFeatureKey]: fromPatient.reducer,
    [fromQuestions.questionsFeatureKey]: fromQuestions.reducer,
  })(state, action);
}

export const getProfileState = createFeatureSelector<State>(
  profileFeatureKey
);

export const selectPatientState = createSelector(
  getProfileState,
  (state: State) => state[fromPatient.patientFeatureKey],
);

export const selectQuestionsState = createSelector(
  getProfileState,
  (state: State) => state[fromQuestions.questionsFeatureKey],
);

import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as DietTypeActions from '../actions/diet-type.actions';
import { DirectoryService } from '@app/core/services/directory.service';
import * as DietTypeSelectors from '@app/shared/store/selectors/diet-type.selectors';
import {select, Store } from '@ngrx/store';
import * as fromShared from '@app/shared/store';

@Injectable()
export class DietTypeEffects {
  loadDietTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DietTypeActions.loadDietTypes),
      withLatestFrom(this.store$.pipe(select(DietTypeSelectors.selectAllDietTypes))),
      switchMap(([action, dietTypeList]) => {
        if (dietTypeList.length) {
          return of(DietTypeActions.loadDietTypesSuccess({ dietTypes: dietTypeList }));
        }

        return this.directoryService.loadDietTypes().pipe(
            map((response) => DietTypeActions.loadDietTypesSuccess({ dietTypes: response })),
            catchError((error) => of(DietTypeActions.loadDietTypesFailure({ error }))),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private directoryService: DirectoryService,
    private store$: Store<fromShared.State>
  ) {}
}

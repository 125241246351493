<section class="offer-agreement-dialog">
  <h1 class="offer-agreement-dialog__title">
    Договор-оферта на предоставление доступа к веб-сервису {{offerAgreement.serviceName}}</h1>
  <div class="offer-agreement-dialog__content">{{ offerAgreement.head }}</div>
  <div class="offer-agreement-dialog__content-text">
    <strong class="offer-agreement-dialog__subtitle">1. Заключение договора</strong>
    <p class="offer-agreement-dialog__text">
      1.1. Публичная оферта, выраженная в настоящем договоре, вступает в силу с момента ее размещения на сайте
      Исполнителя по адресу
      <a
        class="offer-agreement-dialog__text-link"
        [href]="offerAgreementLink"
        target="_blank"
      >
        {{ offerAgreementLink }}
      </a>
    </p>
    <p class="offer-agreement-dialog__text">
      1.2. Моментом полного и безоговорочного принятия предложения Исполнителя заключить договор (акцептом оферты)
      считается одно из следующих действий:
      - регистрация Пользователя в веб-сервисе (создание учетной записи);
      - использование Пользователем веб-сервиса, любых его функций, прочих сервисов и дополнительных услуг;
      - оплата физическим или юридическим лицом стоимости услуг Исполнителя по предоставлению доступа к
      веб-сервису {{ dataDomain.name }}.
      {{ offerAgreement['1.2'] }}
    </p>
    <p class="offer-agreement-dialog__text">
      1.3. В соответствии с п. 3 ст. 434 Гражданского кодекса Российской Федерации настоящий договор считается
      заключенным в письменной форме. Местом заключения договора является город Санкт-Петербург, Российская Федерация.
    </p>
    <p class="offer-agreement-dialog__text">
      1.4. Исполнитель не является плательщиком НДС на основании статьи 346.11 главы 26.2 НК РФ.
    </p>
  </div>
  <div class="offer-agreement-dialog__content-text">
    <strong class="offer-agreement-dialog__subtitle">2. Термины и определения</strong>
    <p class="offer-agreement-dialog__text">
      Заказчик – физическое или юридическое лицо, принявшее (акцептовавшее) настоящую оферту. В соответствии с п. 3 ст.
      438 ГК РФ акцептом оферты является, в том числе, оплата услуг Исполнителя.
    </p>
    <p class="offer-agreement-dialog__text">
      Стороны – совместное наименование Исполнителя и Заказчика/Пользователя для целей настоящего договора.
    </p>
    <p class="offer-agreement-dialog__text">
      Пользователь – физическое лицо, прошедшее авторизацию в веб-сервисе, являющееся Заказчиком либо непосредственно
      пользующееся услугой от имени и по поручению Заказчика.
    </p>
    <p class="offer-agreement-dialog__text">
      Учетная запись – совокупность данных авторизациии иной информации Пользователя, используемой для доступа к
      веб-сервису.
    </p>
    <p class="offer-agreement-dialog__text">
      Пользовательский интерфейс (Вкладка «Подписка») – раздел веб-сервиса, доступный Пользователю после регистрации и
      авторизации, который содержит информацию о подключенных Пользователем тарифах, произведенных оплатах, количестве
      доступных к созданию отчетов и др.сведения, а также предоставляет функциональную возможность удаленного
      взаимодействия Сторон в рамках настоящего договора.
    </p>
    <p class="offer-agreement-dialog__text">
      Услуга – предоставление Пользователю доступа к веб-сервису для использования его функций путем удаленного
      подключения по сети интернет в течение срока, предусмотренного Тарифом.
    </p>
    <p class="offer-agreement-dialog__text">
      Веб-сервис (или сервис, или платформа) – программное обеспечение {{ dataDomain.name }}, расположенное в сети интернет
      по адресу {{ dataDomain.link }}(включая все уровни указанного домена, как функционирующие на дату вступления
      в силу настоящего договора, так и запускаемые и вводимые в эксплуатацию в последующем), а также иное программное
      обеспечение, расположенное на информационных ресурсах Исполнителя, которое может быть предусмотрено Тарифом.
    </p>
    <p class="offer-agreement-dialog__text">
      Тариф – перечень доступных Пользователю функций веб-сервиса (их объем, характеристика, стоимость и т. д.), сроки
      использования веб-сервиса, а также прочие сервисы и дополнительные услуги Исполнителя, предусмотренные Тарифом,
      сведения о которых опубликованы на информационных ресурсах.
    </p>
    <p class="offer-agreement-dialog__text">
      Подписка – совокупность тарифа и периода подписки, дающие Пользователю право использовать веб-сервис в
      установленных ими пределах.
    </p>
    <p class="offer-agreement-dialog__text">
      Стоимость подписки на тариф – сумма, подлежащая уплате за использование веб-сервиса.
    </p>
    <p class="offer-agreement-dialog__text">
      Информационные ресурсы – интернет-сайт Исполнителя {{ offerAgreement.informationResourcesLink }}
    </p>
    <p class="offer-agreement-dialog__text">
      Баланс – сумма поступившей предварительной оплаты от Заказчика.
    </p>
    <p class="offer-agreement-dialog__text">
      Периодподписки– календарный месяц, в течение которого Пользователю доступны оплаченные услуги, который начинается
      с момента оплаты подписки и заканчивается истечением последнего дня срока, либо оформлением новой подписки, в
      случае, если Пользователь исчерпал доступное для генерации количество отчетов и не хочет ждать окончания
      календарного месяца.
    </p>
    <p class="offer-agreement-dialog__text">
      Контент  – текстовые, графические, звуковые и любые другие материалы, которые размещаются Исполнителем на
      информационных ресурсах.
    </p>
    <p class="offer-agreement-dialog__text">
      Автоплатеж – режим автоматического продления периода подписки на веб-сервис, при котором оплата за следующий месяц
      использования веб-сервиса по действующему тарифу производится автоматически по истечении оплаченного периода путем
      списания стоимости тарифа с привязанной Пользователем банковской карты.
      В случае, если при оплате подписки на веб-сервис Пользователь применил промокод (скидку) и оплатил определенный
      период, то оплата за следующий ранее не оплаченный период производится по действующему тарифу в полном размере.
    </p>
    <p class="offer-agreement-dialog__text">
      Отчет – это документ в формате pdf, именуемый «Анализ пищевого статуса, рекомендуемый рацион и режим питания»,
      который формируется в разделе «Рацион», вкладка «Созданные», путем нажатия на кнопку – «Создать».
    </p>
  </div>
  <div class="offer-agreement-dialog__content-text">
    <strong class="offer-agreement-dialog__subtitle">3. Предмет договора</strong>
    <p class="offer-agreement-dialog__text">
      3.1. По настоящему Договору Исполнитель обязуется оказывать Заказчику услуги по предоставлению доступа к
      веб-сервису {{ dataDomain.name }} на условиях «подписки», включая определенные наборы (комплекты) функций веб-сервиса в
      соответствии с выбранным Тарифом (Тарифами), которые действуют в течение определенного ограниченного срока
      (Периода подписки) или в определенном количестве (количество отчетов, доступных для генерации в течение месяца) а
      Заказчик обязуется принимать и оплачивать оказываемые Исполнителем Услуги, в порядке и на условиях, установленных
      настоящим Договором.
    </p>
    <p class="offer-agreement-dialog__text">
      3.2. Заказчик обязуется пользоваться услугами Исполнителя в соответствии с Правилами использования
      веб-сервиса {{ dataDomain.name }}, опубликованными по адресу:
      <a
        class="offer-agreement-dialog__text-link"
        [href]="polzovatelskoeSoglashenieLink"
        target="_blank"
      >
        {{ polzovatelskoeSoglashenieLink }}
      </a>
    </p>
    <p class="offer-agreement-dialog__text">
      3.3. Заказчик и/или действующий от его имени Пользователь не имеют права сдавать в прокат, аренду, предоставлять
      во временное пользование, распространять, за плату или бесплатно предоставлять третьим лицам (за исключением
      уполномоченных Заказчиком Пользователей) доступ к веб-сервису. Указанное ограничение не исключает возможности
      Пользователя самостоятельно использовать веб-сервис в интересах третьих лиц, оставаясь ответственным перед
      Исполнителем за соблюдение условий Договора.В случае нарушения Заказчиком или Пользователем условий настоящего
      Договора Исполнитель имеет право блокировать доступ Пользователю к услугам до устранения таких нарушений.
    </p>
    <p class="offer-agreement-dialog__text">
      3.4. В услугу не входит настройка программного или аппаратного обеспечения Пользователя. Консультации по вопросам
      функционирования веб-сервиса осуществляются службой поддержки веб-сервиса (info@nplanner.ru)
    </p>
    <p class="offer-agreement-dialog__text">
      3.5. Исполнитель оставляет за собой право вносить изменения в настоящий Договор, Правила и другие документы в
      одностороннем порядке при условии предварительного уведомления об этом Заказчика, путем опубликования
      соответствующей информации в веб-сервисе или на информационных ресурсах Исполнителя. Все изменения, вступают в
      силу с даты, которая указана в соответствующей публикации в веб-сервисе или на информационных ресурсах
      Исполнителя, но не ранее чем через 3 дня с момента публикации соответствующей информации. В любом случае, каждый
      раз, когда Пользователь использует веб-сервис, он подтверждает, что Заказчик ознакомился и принял все условия
      Договора-оферты, Правила и другие документы в редакции, которая установлена Исполнителем как действующая на момент
      использования веб-сервиса.
    </p>
    <p class="offer-agreement-dialog__text">
      3.6. Если одно или более положений настоящего Договора являются по какой-либо причине недействительными, не
      имеющими юридической силы, такая недействительность не оказывает влияния на действительность любого другого
      положения договора, которые остаются в силе.
    </p>
    <p class="offer-agreement-dialog__text">
      3.7. Исключительные и неисключительные права на веб-сервис и иное программное обеспечение, используемые
      Исполнителем в целях настоящего Договора, принадлежат Исполнителю и не передаются Заказчику или/или Пользователю.
    </p>
    <p class="offer-agreement-dialog__text">
      3.8. Исполнитель в любое время по собственному усмотрению и без предварительного уведомления Пользователя вправе
      передать полностью или частично свои права и обязанности, вытекающие из настоящего Договора, а также иных
      документов, применимых к правоотношениям сторон, любому третьему лицу.
    </p>
  </div>

  <div class="offer-agreement-dialog__content-text">
    <strong class="offer-agreement-dialog__subtitle">4. Порядок оплаты и оказания услуг</strong>
    <p class="offer-agreement-dialog__text">
      4.1. Для получения доступа к веб-сервису и определения состава и (или) количества услуг Пользователь
      регистрируется и авторизуется в веб-сервисе Исполнителя, в котором находится перечень тарифов веб-сервиса,
      доступных к заказу, с указанием их содержания, объема, характеристик, стоимости, срока использования и других
      необходимых сведений для выбора услуги.
    </p>
    <p class="offer-agreement-dialog__text">
      4.2. Услуги предоставляются на условиях предоплаты, которая осуществляется Заказчиком путем перечисления денежных
      средств на расчетный счет Исполнителя или другими способами, опубликованными на ресурсе Исполнителя. Датой оплаты
      услуг считается дата зачисления денежных средств на текущий счет Исполнителя.
    </p>
    <p class="offer-agreement-dialog__text">
      4.3. Внесение предоплаты осуществляется любым из способов, доступных во вкладке «Подписка» веб-сервиса.
    </p>
    <p class="offer-agreement-dialog__text">
      4.4. После оплаты выбранного тарифа по умолчанию включается режим «Автоплатеж». Его можно отключить в личном
      кабинете во вкладке «Подписка».
    </p>
    <p class="offer-agreement-dialog__text">
      4.5. Поступившие от Заказчика денежные средства отражаются в пользовательском интерфейсе во вкладке «Подписка», а
      Пользователь получает возможность использовать веб-сервис в соответствии с выбранным тарифом.
      Если оплаченноеПользователем количество Отчетов, доступных для генерации, закончится до окончания периода
      подписки, то он сможет пользоваться всеми возможностями веб-сервиса, кроме генерации новых Отчетов. Для получения
      возможности генерации новых Отчетов Пользователю необходимо оформить новую подписку на веб-сервис.
      После окончания оплаченного периода подписки Пользователю будет доступна только вкладка «Подписки».
    </p>
    <p class="offer-agreement-dialog__text">
      4.6. В случае неиспользования Заказчиком услуг (отсутствие у Заказчика необходимости в услугах, невозможность
      получения услуг, которая обусловлена техническими или иными проблемами со стороны Заказчика или Пользователя,
      блокировка учетной записи Пользователя) стоимость оплаченных Заказчиком услуг не возвращается, при этом услуга
      считается оказанной в полном объеме.
    </p>
    <p class="offer-agreement-dialog__text">
      4.7. В случае неиспользования Заказчиком услуг по вине Исполнителя (технических сбоев в работе веб-сервиса)
      Исполнитель обязуется предоставить Заказчику дополнительный бесплатный доступ к веб-сервису на то количество дней,
      в течение которых отсутствовал или был невозможным доступ. Пользователь обязуется незамедлительно информировать
      Исполнителя о невозможности получения Услуг по причине технических сбоев в работе веб-сервиса.
    </p>
    <p class="offer-agreement-dialog__text">
      4.8. В случае возникновения обстоятельств, не находящихся под контролем Исполнителя включая, но не ограничиваясь:
      сбои в телекоммуникационных и энергетических сетях, работе программно-аппаратных комплексов третьих лиц и/или
      каналов передачи данных, не принадлежащих Исполнителю; действие вредоносных программ, а также недобросовестные
      действия третьих лиц, направленные на несанкционированный доступ и (или) выведение из строя программного и (или)
      аппаратного обеспечения Исполнителя; прекращение предоставления правообладателем доступа к веб-службам,
      программным продуктам, контенту и/или поддержки таких веб-служб, и/или блокирование доступа к отдельным
      веб-службам или целым сегментам сети Интернет, осуществляемое третьими лицами, исключающих возможность оказания
      Исполнителем Услуг на условиях, указанных в настоящем договоре, для всех или некоторых Пользователей, в т.ч.
      Пользователей, находящихся на определенной территории или попавших в санкционные списки, согласно законодательству
      определенных государств, Исполнитель имеет право аннулировать соответствующий заказ Заказчика путем прекращения
      доступа Пользователя к функциям веб-сервиса. О возникновении вышеуказанных или подобных обстоятельств Исполнитель
      уведомляет Заказчика посредством направления уведомления на электронный адрес Заказчика, или через интерфейс
      Пользователя.
    </p>
    <p class="offer-agreement-dialog__text">
      4.9. Принимая условия настоящего Договора, Пользователь подтверждает наличие у него квалификации и
      профессиональных знаний в области питания и медицины, достаточных для правильного и надлежащего использования
      функций веб-сервиса и составления с его помощью правильных и безопасных документов (рационов, отчетов и др.
      документов).
    </p>
    <p class="offer-agreement-dialog__text">
      4.10. В случае отсутствия у Пользователя должной квалификации и профессиональных знаний, он обязуется утвердить у
      специализирующегося врача документы (рационы, отчеты и др. документы), составленные им с помощью веб-сервиса,
      перед их применением. Пользователь, нарушивший это обязательство, принимает на себя все риски и последствия,
      связанные с таким нарушением.
    </p>
  </div>

  <div class="offer-agreement-dialog__content-text">
    <strong class="offer-agreement-dialog__subtitle">5. Приемка услуг</strong>
    <p class="offer-agreement-dialog__text">
      5.1. Стороны подтверждают и соглашаются, что Услуги считаются оказанными в момент предоставления доступа к
      оплаченному набору функций веб-сервиса. В случае отсутствия иных доказательств, надлежащим доказательством факта
      предоставления доступа к функциям веб-сервиса являются данные автоматизированной системы учета платежей и услуг
      Исполнителя.
    </p>
    <p class="offer-agreement-dialog__text">
      5.2. Услуга, оказанная Исполнителем, будет считаться принятой Заказчиком в полном объеме и без претензий по
      качеству, при отсутствии мотивированных письменных возражений со стороны Заказчика, направленных Исполнителю в
      порядке и в сроки, предусмотренные пунктами 5.3.-5.4. настоящего Договора.
    </p>
    <p class="offer-agreement-dialog__text">
      5.3. По истечение оплаченного периода подписки на веб-сервис, Услуги за этот период считаются оказанными
      надлежащим образом и представленными к приемке Заказчиком.
      В течение 3 (трех) рабочих дней с момента окончания оплаченного периода доступа к веб-сервису Заказчик вправе
      направить в адрес Исполнителя мотивированные письменные возражения (мотивированный отказ от принятия оказанных
      услуг).
      В случае ненаправления Заказчиком оригинала мотивированных письменных возражений в указанный срок, Услуги,
      оказанные Исполнителем в отчетном периоде, считаются принятыми Заказчиком.
    </p>
    <p class="offer-agreement-dialog__text">
      5.4. Мотивированные письменные возражения (мотивированный отказ в принятии оказанных услуг) могут быть направлены
      Заказчиком по электронной почте info@nplanner.ru
    </p>
    <p class="offer-agreement-dialog__text">
      5.5. По требованию Заказчика, направленному по электронной почте info@nplanner.ru, Исполнитель обязуется в
      разумные сроки предоставлять акт сверки взаимных расчетов.
    </p>
    <p class="offer-agreement-dialog__text">
      5.6. Все электронные документы, составленные и подписанные Исполнителем в электронной форме посредством факсимиле
      и опубликованные в веб-сервисе, либо направленные на электронную почту Заказчика с электронной почты, позволяющей
      установить факт отправления такого документа Исполнителем, признаются Сторонами совершенными в надлежащей форме и
      имеющими юридическую силу.
    </p>
    <p class="offer-agreement-dialog__text">
      5.7. По запросу Заказчика, являющегося юридическим лицом или индивидуальным предпринимателем, Акт может быть
      оформлен в виде документа на бумажном носителе с подписью уполномоченного лица и печатью Исполнителя. Оригинал
      Акта направляется Исполнителем заказным письмом по адресу, указанному в запросе.
    </p>
  </div>

  <div class="offer-agreement-dialog__content-text">
    <strong class="offer-agreement-dialog__subtitle">6. Заключительные положения</strong>
    <p class="offer-agreement-dialog__text">
      6.1. Настоящий договор и отношения между Заказчиком и Исполнителем регулируются и толкуются в соответствии с
      законодательством Российской Федерации. Вопросы, не урегулированные Соглашением, подлежат разрешению в
      соответствии с законодательством Российской Федерации.
    </p>
    <p class="offer-agreement-dialog__text">
      6.2. Настоящий договор заключается на неопределенный срок и распространяет свое действие на Заказчиков, получающих
      доступ к веб-сервису и осуществляющих его использование, как до даты опубликования настоящего договора, так и
      после даты его опубликования на информационных ресурсах Исполнителя.
    </p>
    <p class="offer-agreement-dialog__text">
      6.3. Стороны согласились, что все споры, возникающие из отношений Сторон, регулируемых настоящим Договором, должны
      разрешаться вАрбитражном суде Санкт-Петербурга и Ленинградской области с обязательным соблюдением досудебного
      претензионного порядка урегулирования споров.
      Претензия должна содержать требования заинтересованной стороны и их обоснование с указанием нарушенных другой
      стороной норм законодательства и (или) условий договора. К претензии должны быть приложены копии документов,
      подтверждающих изложенные в ней обстоятельства.
      Сторона, которая получила претензию, обязана ее рассмотреть и направить письменный мотивированный ответ другой
      стороне в течение десяти рабочих дней с момента получения претензии. Заинтересованная сторона вправе обратиться в
      суд по истечении 30 календарных дней со дня направления претензии либо в случае, когда ответ на претензию от
      другой стороны был получен, но заинтересованная сторона по каким-либо причинам с ним не согласна.
    </p>
    <p class="offer-agreement-dialog__text">
      6.4. Если иное не предусмотрено законом либо настоящим Договором, заявления, уведомления, извещения, требования
      или иные юридически значимые сообщения, с которыми закон или договор связывает наступление гражданско-правовых
      последствий для другого лица, влекут наступление таких последствий с момента доставки соответствующего сообщения
      этому лицу или его представителю.Сообщение считается доставленным и в тех случаях, когда оно поступило адресату,
      но по обстоятельствам, зависящим от него, не было ему вручено или адресат не ознакомился с ним.
    </p>
    <p class="offer-agreement-dialog__text">
      6.5. Заказчик несет риск последствий неполучения юридически значимых сообщений, направляемых Исполнителем по
      адресу места нахождения Заказчика и/или адресу его электронной почты, указанных Заказчиком при регистрации в
      аккаунте Пользователя, в том числе в случае указания Заказчиком недостоверных данных, отсутствия по адресу места
      нахождения своего органа или представителя либо уклонения от получения сообщений.
    </p>
    <p class="offer-agreement-dialog__text">
      6.6. Стороны обязуются принимать все необходимые меры по безопасности и защите конфиденциальности информации и
      документов, обмен которым осуществляется в связи с выполнением условий настоящего Договора.
    </p>
    <p class="offer-agreement-dialog__text">
      6.7. Порядок и условия использования Заказчиком/Пользователем веб-сервиса, а также отношения между Исполнителем и
      Заказчиком регулируются настоящим Договором-офертой, Правилами использования веб-сервиса Nutrient Planner, а также
      Политикой защиты персональных данных и Политикой возврата денежных средств
    </p>
  </div>

  <div class="offer-agreement-dialog__content-text">
    <strong class="offer-agreement-dialog__subtitle">7. Реквизиты Исполнителя</strong>
    <p class="offer-agreement-dialog__text">{{ references.orgn }}</p>
    <p class="offer-agreement-dialog__text">ИНН: {{ references.inn }}</p>
    <p class="offer-agreement-dialog__text">Р/С: {{ references.pc }}</p>
    <p class="offer-agreement-dialog__text">Банк: {{ references.bank }}</p>
    <p class="offer-agreement-dialog__text">БИК: {{ references.bik }}</p>
    <p class="offer-agreement-dialog__text">К/С: c</p>
    <p class="offer-agreement-dialog__text">Почтовый адрес: {{ references.postalAddress }}</p>
    <p class="offer-agreement-dialog__text">
      Адрес электронной почты: {{ references.email }} <br>
      Телефон: {{ references.phone }}
    </p>
  </div>
</section>

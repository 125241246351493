import { createAction, props } from '@ngrx/store';
import { Dictinary } from '@app/shared/models';

export const loadProductGroup = createAction(
  '[Directory / API] Load Product Group'
);
export const loadProductGroupSuccess = createAction(
  '[Directory / API] Load Product Group Success',
  props<{ productGroup: any[] }>()
);
export const loadProductGroupFailure = createAction(
  '[Directory / API] Load Product Group Failure',
  props<{ error: any }>()
);

export const clearProductGroup = createAction(
  '[Directory] Clear Product Group',
);

export const addExcludedProductGroup = createAction(
  '[Directory] Add Excluded Product Group',
  props<{ excludedProductGroup: Dictinary }>()
);

export const removeExcludedProductGroup = createAction(
  '[Directory] Remove Excluded Product Group',
  props<{ removeExcludedProductGroup: Dictinary }>()
);

export const addExcludedProductGroupFromSelect = createAction(
  '[Directory] Add Excluded Product Group From Select',
  props<{ excludedProductGroup: Dictinary }>()
);

export const removeExcludeProductGroupFromSelect = createAction(
  '[Directory] Remove Exclude Product Group From Select',
  props<{ excludedProductGroup: Dictinary }>()
);

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromShared from '@app/shared/store';
import * as RecipeGroupActions from '../actions/recipe-group.actions';
import * as RecipeGroupSelectors from '../selectors/recipe-group.selectors';
import { DirectoryService } from '@app/core/services/directory.service';

@Injectable()
export class RecipeGroupEffects {
  loadRecipeGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipeGroupActions.loadRecipeGroup),
      withLatestFrom(this.store.pipe(select(RecipeGroupSelectors.selectAllRecipeGroupList))),
      switchMap(([action, allRecipeGroupList]) => {
        return (allRecipeGroupList.length ? of(allRecipeGroupList) : this.directoryService.loadRecipeGroupList())
        .pipe(
          map((response) => {
            return RecipeGroupActions.loadRecipeGroupSuccess({ recipeGroup: response });
          }),
          catchError((error) => {
            return of(RecipeGroupActions.loadRecipeGroupFailure({ error }));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromShared.State>,
    private directoryService: DirectoryService,
  ) {}
}

import { PatientEffects } from './patient.effects';
import { QuestionsEffects } from './questions.effects';

export const effects: any[] = [
  PatientEffects,
  QuestionsEffects,
];

export * from './patient.effects';
export * from './questions.effects';

import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';

import { hostnameReplace } from '@app/shared/utils/utils';
import { DomainsData } from '@app/shared/constants/domains';
import { Domain, DomainColor, OfferAgreement, ProtectionPolicy, TermsOfUse } from '@app/shared/models';
import { Language } from '@app/shared/constants/language';

@Injectable({
  providedIn: 'root',
})

export class DomainService {
  constructor(private location: PlatformLocation) {}

  getDomain(): string {
    return hostnameReplace(this.location.hostname)
  }

  getDataDomain(): Domain {
    return DomainsData[this.getDomain()];
  }

  getLang(): Language {
    return DomainsData[this.getDomain()]?.lang;
  }

  getColoredDomain(): DomainColor {
    return DomainsData[this.getDomain()]?.color;
  }

  getTermsDomain(): TermsOfUse {
    return DomainsData[this.getDomain()]?.termsOfUse;
  }

  getOfferAgreement(): OfferAgreement {
    return DomainsData[this.getDomain()]?.offerAgreement;
  }

  getProtectionPolicy(): ProtectionPolicy {
    return DomainsData[this.getDomain()]?.protectionPolicy;
  }
}

import { TimeSlotRangeStepMinutes } from '@app/shared/models/time-slot-range-step-minutes';
import { Directory } from '@app/shared/models';

export function convertArrayToObject(array: any[], key: string) {
  return array.reduce((obj, item) => ({ ...obj, [item[key]]: item }), {});
}

export function removeObjectProperty(originalObj, array: string[]) {
  return array.reduce((obj, key: string) => {
    const { [key]: value, ...rest } = obj;
    return rest;
  }, originalObj);
}

export function getObjectPropertyToArray(array: any[], key: string) {
  return array.reduce((prev, curr) => [...prev, ...[curr[key]]], []);
}

export function getUniqueId(): string {
  return `_${ Math.random().toString(36).substr(2, 9) }`;
}

// **ахуенный код, надежный блять, как швейцарские часы (нет). Лебовски
export function getTimeSlotRange(
  stepMinutes: TimeSlotRangeStepMinutes,
): string[] {
  return Array(24)
    .fill(null)
    .reduce((hourRange, _, hourIndex) => {
      const numTimeSlots = 60 / stepMinutes;
      return Array(numTimeSlots)
        .fill(null)
        .reduce((timeSlotRange, _, timeSlotIndex) => {
          const hours = (hourIndex + '').padStart(2, '0');
          const minutes = (timeSlotIndex * stepMinutes + '').padStart(2, '0');
          hourRange.push(`${ hours }:${ minutes }`);
          return hourRange;
        }, hourRange);
    }, []);
}

export function excludedHandbook(arr: any[], excludedArr: any[]): any[] {
  return arr
    // .filter((itemFirst: Directory): boolean => itemFirst.id > 0)
    .filter((itemSecond: Directory) => !(excludedArr.some(excludedItem => excludedItem.id === itemSecond.id)));
}

export function removeDuplicatesArr(arr: any[], fieldFilter: string = 'id'): any[] {
  return arr.filter((item, index: number, self: any[]): boolean => (
    self.findIndex(t => t[fieldFilter] === item[fieldFilter]) === index),
  );
}

export function hostnameReplace(hostname: string): string {
  const pattern = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(?:stage\.|dev\.)?([^:\/\n]+)/im;
  return hostname.match(pattern)[1];
}

export function isEqualArray(firstArr, secondArr): boolean {
  if (firstArr.length !== secondArr.length) {
    return false;
  }

  for (let i = 0; i < firstArr.length; i++) {
    const obj1 = firstArr[i];
    const obj2 = secondArr[i];

    const keys = Object.keys(obj1);

    for (const key of keys) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
}


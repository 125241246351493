import {
  combineReducers,
  Action,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as fromCookingType from './cooking-type.reducer';
import * as fromDietRestriction from './diet-restriction.reducer';
import * as fromDietType from './diet-type.reducer';
import * as fromDiseases from './diseases.reducer';
import * as fromGender from './gender.reducer';
import * as fromMedicaments from './medicaments.reducer';
import * as fromProducts from './products.reducer';
import * as fromDrinks from './drinks.reducer';
import * as fromWorkingGroup from './working-group.reducer';
import * as fromSportLevel from '@app/shared/store/reducers/sport-level.reducer';
import * as fromFoodList from '@app/shared/store/reducers/food-list.reducer';

import * as fromDrinkGroup from '@app/shared/store/reducers/drink-group.reducer';
import * as fromProductGroup from '@app/shared/store/reducers/product-group.reducer';
import * as fromRecipeGroup from '@app/shared/store/reducers/recipe-group.reducer';

import * as fromDish from '@app/shared/store/reducers/dish.reducer';
import * as fromUnits from '@app/shared/store/reducers/units.reducer';

export const sharedFeatureKey = 'shared';

export interface State {
  [fromCookingType.cookingTypeFeatureKey]: fromCookingType.State;
  [fromDietRestriction.featureKey]: fromDietRestriction.State;
  [fromDietType.dietTypeFeatureKey]: fromDietType.State;
  [fromDiseases.featureKey]: fromDiseases.State;
  [fromGender.genderFeatureKey]: fromGender.State;
  [fromMedicaments.featureKey]: fromMedicaments.State;
  [fromProducts.featureKey]: fromProducts.State;
  [fromDrinks.featureKey]: fromDrinks.State;
  [fromWorkingGroup.workingGroupFeatureKey]: fromWorkingGroup.State;
  [fromSportLevel.sportLevelFeatureKey]: fromSportLevel.State;
  [fromFoodList.foodListFeatureKey]: fromFoodList.State;

  [fromDrinkGroup.drinkGroupFeatureKey]: fromDrinkGroup.State;
  [fromProductGroup.productGroupFeatureKey]: fromProductGroup.State;
  [fromRecipeGroup.recipeGroupFeatureKey]: fromRecipeGroup.State;

  [fromDish.dishFeatureKey]: fromDish.State;
  [fromUnits.unitsFeatureKey]: fromUnits.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    [fromCookingType.cookingTypeFeatureKey]: fromCookingType.reducer,
    [fromDietRestriction.featureKey]: fromDietRestriction.reducer,
    [fromDietType.dietTypeFeatureKey]: fromDietType.reducer,
    [fromDiseases.featureKey]: fromDiseases.reducer,
    [fromGender.genderFeatureKey]: fromGender.reducer,
    [fromMedicaments.featureKey]: fromMedicaments.reducer,
    [fromProducts.featureKey]: fromProducts.reducer,
    [fromDrinks.featureKey]: fromDrinks.reducer,
    [fromWorkingGroup.workingGroupFeatureKey]: fromWorkingGroup.reducer,
    [fromSportLevel.sportLevelFeatureKey]: fromSportLevel.reducer,
    [fromFoodList.foodListFeatureKey]: fromFoodList.reducer,

    [fromDrinkGroup.drinkGroupFeatureKey]: fromDrinkGroup.reducer,
    [fromProductGroup.productGroupFeatureKey]: fromProductGroup.reducer,
    [fromRecipeGroup.recipeGroupFeatureKey]: fromRecipeGroup.reducer,

    [fromDish.dishFeatureKey]: fromDish.reducer,
    [fromUnits.unitsFeatureKey]: fromUnits.reducer,
  })(state, action);
}

export const getSharedState = createFeatureSelector<State>(sharedFeatureKey);

export const selectCookingTypeState = createSelector(
  getSharedState,
  (state: State) => state[fromCookingType.cookingTypeFeatureKey],
);

export const selectDietRestrictionState = createSelector(
  getSharedState,
  (state: State) => state[fromDietRestriction.featureKey],
);

export const selectDietTypeState = createSelector(
  getSharedState,
  (state: State) => state[fromDietType.dietTypeFeatureKey],
);

export const selectDiseasesState = createSelector(
  getSharedState,
  (state: State) => state[fromDiseases.featureKey],
);

export const selectGenderState = createSelector(
  getSharedState,
  (state: State) => state[fromGender.genderFeatureKey],
);

export const selectMedicamentsState = createSelector(
  getSharedState,
  (state: State) => state[fromMedicaments.featureKey],
);

export const selectProductsState = createSelector(
  getSharedState,
  (state: State) => state[fromProducts.featureKey],
);

export const selectDrinksState = createSelector(
  getSharedState,
  (state: State) => state[fromDrinks.featureKey],
);

export const selectWorkingGroupState = createSelector(
  getSharedState,
  (state: State) => state[fromWorkingGroup.workingGroupFeatureKey],
);

export const selectSportLevelState = createSelector(
  getSharedState,
  (state: State) => state[fromSportLevel.sportLevelFeatureKey]
);

export const selectFoodListState = createSelector(
  getSharedState,
  (state: State) => state[fromFoodList.foodListFeatureKey]
);

export const selectDrinkGroupListState = createSelector(
  getSharedState,
  (state: State) => state[fromDrinkGroup.drinkGroupFeatureKey]
);

export const selectProductGroupListState = createSelector(
  getSharedState,
  (state: State) => state[fromProductGroup.productGroupFeatureKey]
);

export const selectRecipeGroupListState = createSelector(
  getSharedState,
  (state: State) => state[fromRecipeGroup.recipeGroupFeatureKey]
);

export const selectDishState = createSelector(
  getSharedState,
  (state: State) => state[fromDish.dishFeatureKey]
);

export const selectUnitsState = createSelector(
  getSharedState,
  (state: State) => state[fromUnits.unitsFeatureKey]
);

import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as QuestionsActions from '../actions/questions.actions';

export const questionsFeatureKey = 'questions';

const questionSteps = [
  {
    id: 1,
    path: 'intro',
    isValid: false,
  },
  {
    id: 2,
    path: 'goal',
    isValid: false,
  },
  {
    id: 3,
    path: 'food-style',
    isValid: false,
  },
  {
    id: 4,
    path: 'food-location',
    isValid: false,
  },
  {
    id: 5,
    path: 'food-preferences',
    isValid: false,
  },
  {
    id: 6,
    path: 'chronic-diseases',
    isValid: false,
  },
  {
    id: 7,
    path: 'diseases',
    isValid: false,
  },
  {
    id: 8,
    path: 'allergy',
    isValid: false,
  },
  {
    id: 9,
    path: 'toilet',
    isValid: false,
  },
  {
    id: 10,
    path: 'medicines',
    isValid: false,
  },
  {
    id: 11,
    path: 'sleep',
    isValid: false,
  },
  {
    id: 12,
    path: 'stress',
    isValid: false,
  },
];

export interface State extends EntityState<any> {
  selectedQuestionId: number;
  stepCount: number;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (questionStep: any) => questionStep.id,
});

export const initialState: State = adapter.getInitialState({
  selectedQuestionId: 1,
  stepCount: 1
});

export const reducer = createReducer(
  initialState,

  on(QuestionsActions.setQuestionSteps, (state) => ({
    ...adapter.setAll(questionSteps, state),
  })),

  on(QuestionsActions.setQuestionStepsComplete, (state) => ({
      ...adapter.map(
        (questionStep) =>
          state.selectedQuestionId >= questionStep.id
            ? { ...questionStep, isValid: true }
            : questionStep,
        state,
      ),
    }),
  ),

  on(QuestionsActions.setCurrentQuestionStep, (state, { selectedQuestionId }) => ({
    ...state,
    selectedQuestionId,
  }),
),

  // on(QuestionsActions.setCurrentQuestionStep, (state, { selectedQuestionId }) => ({
  //     ...state,
  //     selectedQuestionId: state.ids.length === selectedQuestionId ? selectedQuestionId : selectedQuestionId + 1,
  //   }),
  // ),

  on(QuestionsActions.changeValidationStatus, (state, { isValid }) => ({
    ...adapter.updateOne(
      { id: state.selectedQuestionId, changes: { isValid } },
      state,
    ),
    valid: isValid,
  })),

  on(QuestionsActions.navigatePreviousQuestion, (state) => ({
    ...state,
    selectedQuestionId:
      state.selectedQuestionId === state.ids[0]
        ? state.selectedQuestionId
        : state.selectedQuestionId - state.stepCount,
  })),

  on(QuestionsActions.navigateNextQuestion, (state) => ({
    ...state,
    selectedQuestionId:
      state.selectedQuestionId === state.ids.length
        ? state.selectedQuestionId
        : state.selectedQuestionId + state.stepCount,
  })),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectQuestionIds = selectIds;
export const selectQuestionEntities = selectEntities;
export const selectAllQuestions = selectAll;
export const selectQuestionTotal = selectTotal;

export const getSelectedQuestionId = (state: State) => state.selectedQuestionId;

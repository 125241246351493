<button
  class="button"
  [ngClass]="[typeView, getTypeViewClass()]"
  [class.button--min-width-default]="minWidthDefaultActive"
  [class.button--nowrap]="nowrap"
  [type]="type"
  [disabled]="disabled || isLoading"
  (click)="onButtonClick($event)"
>
  <app-spinner
    *ngIf="isLoading"
    [size]="spinnerSize"
  ></app-spinner>

  <app-notification-counter
    *ngIf="notification"
    class="button__notification"
    [counter]="notificationCounter"
  ></app-notification-counter>

  <span
    *ngIf="text"
    class="button__text"
    [class.button__text--loading]="isLoading"
  >
    {{ text }}
  </span>

  <!-- * Если нужно управлять иконкой где она применилась - используем ng-content -->
  <ng-content></ng-content>

  <!-- * Если стили и размеры иконки постоянны, то можем использовать svgIcon -->
  <app-svg-icon
    *ngIf="svgIcon"
    class="button__icon button__icon--{{svgIcon}}"
    [class.button__icon--loading]="isLoading"
    [icon]="svgIcon"
    [fill]="svgFill"
    [width]="svgWidth"
    [height]="svgHeight"
  ></app-svg-icon>
</button>

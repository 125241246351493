import { SearchList } from '@app/shared/models/patient/search-list.model';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  loadFoodList,
  loadFoodListFailure,
  loadFoodListSuccess,
  resetFoodList,
} from '../actions';

export const foodListFeatureKey = 'food-list';

export interface State {
  isLoading: boolean;
  foodList: SearchList[];
}

export const initialState: State = {
  isLoading: false,
  foodList: [],
};
const foodListReducer: ActionReducer<State> = createReducer(
  initialState,
  on(loadFoodList, (state: State): State => ({ ...state, isLoading: true })),
  on(
    loadFoodListSuccess,
    (state: State, { foodList }): State => ({ ...state, foodList, isLoading: false }),
  ),
  on(loadFoodListFailure, (state: State): State => ({ ...state, isLoading: false })),
  on(resetFoodList, (state: State): State => ({
    ...state,
    foodList: [],
  })),
);

export function reducer(state: State, action: Action): State {
  return foodListReducer(state, action);
}

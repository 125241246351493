import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { QueryParams, Dictinary } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class DirectoryService {
  constructor(private http: HttpClient) {}

  loadDietRestrictions(): Observable<any[]> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/diet_restriction`);
  }
  // todo хер пойми как оно работает, необходимо разобраться
  loadMedicaments(parameters?: QueryParams): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/medicaments`, {
      params: { ...parameters },
    });
  }
  // todo хер пойми как оно работает, необходимо разобраться
  loadDiseases(parameters?: QueryParams): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/diseases`, {
      params: { ...parameters },
    });
  }
  // todo хер пойми как оно работает, необходимо разобраться
  loadProducts(queryParams): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/products`, {
      params: { ...queryParams },
    });
  }

  // todo хер пойми как оно работает, необходимо разобраться
  loadFoodList(parameters?: QueryParams): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/products_recipes`, {
      params: { ...parameters },
    });
  }

  // todo хер пойми как оно работает, необходимо разобраться
  loadDrinks(parameters?: QueryParams): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/products`, {
      params: { ...parameters, only_drinks: 'true' },
    });
  }

  loadDietTypes(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/diet_type`);
  }

  loadCategories(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/category`);
  }

  loadWorkingGroups(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/working_groups`);
  }

  loadGenderTypes(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/gender`);
  }

  loadCookingTypes(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/cooking_type?limit=11`);
  }

  loadSportLevelList(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/sports`);
  }

  loadDrinkGroupList(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/tags/group/drink`);
  }

  loadProductGroupList(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/tags/group/product`);
  }

  loadRecipeGroupList(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/tags/group/recipe`);
  }

  loadDishes(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/dish`);
  }

  loadUnits(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/units`);
  }
}

import { QueryParams } from '@app/shared/models';
import { SearchList } from '@app/shared/models/patient/search-list.model';
import { createAction, props } from '@ngrx/store';

export const loadFoodList = createAction(
  '[Directory / API] Load Food List',
  props<QueryParams>(),
);

export const loadFoodListSuccess = createAction(
  '[Directory / API] Load Food List Success',
  props<{ foodList: SearchList[] }>(),
);

export const loadFoodListFailure = createAction(
  '[Directory / API] Load Food List Failure',
  props<{ error: any }>(),
);

export const resetFoodList = createAction(
  '[Food List] Reset Food List',
);

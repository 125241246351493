import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromShared from '@app/shared/store';
import * as DrinkGroupActions from '../actions/drink-group.actions';
import * as DrinkGroupSelectors from '../selectors/drink-group.selectors';
import { DirectoryService } from '@app/core/services/directory.service';

@Injectable()
export class DrinkGroupEffects {
  loadDrinkGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrinkGroupActions.loadDrinkGroup),
      withLatestFrom(this.store.pipe(select(DrinkGroupSelectors.selectAllDrinkGroupList))),
      switchMap(([action, allDrinkGroupList]) => {
        return (allDrinkGroupList.length ? of(allDrinkGroupList) : this.directoryService.loadDrinkGroupList())
        .pipe(
          map((response) => {
            return DrinkGroupActions.loadDrinkGroupSuccess({ drinkGroup: response });
          }),
          catchError((error) => {
            return of(DrinkGroupActions.loadDrinkGroupFailure({ error }));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromShared.State>,
    private directoryService: DirectoryService,
  ) {}
}

import { Dictinary } from '@app/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as DietRestrictionActions from '../actions/diet-restriction.actions';

export const featureKey = 'diet-restrictions';
export interface State extends EntityState<Dictinary> {
  isLoading: boolean;
}
export const adapter: EntityAdapter<Dictinary> = createEntityAdapter<Dictinary>({
  selectId: (item: Dictinary) => item.id,
  sortComparer: false,
});
export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,
  on(DietRestrictionActions.loadDietRestriction, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(DietRestrictionActions.loadDietRestrictionSuccess, (state, { dietRestrictions }) => ({
    ...adapter.setAll(dietRestrictions, state),
    isLoading: false,
  })),

  on(DietRestrictionActions.loadDietRestrictionFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDietRestrictionEntities = selectEntities;
export const selectAllDietRestrictions = selectAll;

export const selectIsLoading = (state: State) => state.isLoading;
